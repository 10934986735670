export const SET_USER = "SET_USER";
export const USERS_ERROR = "USERS_ERROR";
export const USERS_AUTH_CHECKING = "USERS_AUTH_CHECKING";

export const USER_RETRIEVE = "USER_RETRIEVE";
export const USER_DETAILS = "USER_DETAILS";
export const USER_SET_VIEW_MODE = "USER_SET_VIEW_MODE";

export const PROMO_RETRIEVE = "PROMO_RETRIEVE";
export const PROMO_NEW = "PROMO_NEW";

export const AFFILIATE_RETRIEVE = "AFFILIATE_RETRIEVE";
export const AFFILIATE_NEW = "AFFILIATE_NEW";

export const SET_PAGE_DATA = "SET_PAGE_DATA";

export const REPORT_AFFILIATE_LIST = "REPORT_AFFILIATE_LIST";
export const REPORT_AFFILIATE_INSERT = "REPORT_AFFILIATE_INSERT";

export const CARD_INSERT = "CARD_INSERT";
export const CARD_RETRIEVE = "CARD_RETRIEVE";

export const PROSPECT_RETRIEVE = "PROSPECT_RETRIEVE";

export const FILES_SET = "FILES_SET";
export const FILES_RETRIEVE = "FILES_RETRIEVE";

export const SUBSCRIPTION_SET = "SUBSCRIPTION_SET";
export const SUBSCRIPTION_RETRIEVE = "SUBSCRIPTION_RETRIEVE";

export const TEMPLATE_RETRIEVE = "TEMPLATE_RETRIEVE";
