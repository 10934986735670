import React from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import NewPromoForm from './NewPromoForm'


class NewPromoModal extends React.Component {
	state = {
		error: {},
	}

	submitHandler = event => {
		event.preventDefault()
	}

	componentDidMount() {
		// this.props.resetMessage()
	}

	changeHandler = event => {
		this.setState({
			[event.target.name]: event.target.value
		})
	}
	render() {
		const customStyles = {
			content: {
				top: '50%',
				left: '50%',
				right: 'auto',
				bottom: 'auto',
				marginRight: '-50%',
				width: '580px',
				transform: 'translate(-50%, -50%)'
			}
		};

		let { orderInfo, serviceInfo } = this.props

		return (
			<Modal
				isOpen={this.props.isOpen}
				onRequestClose={this.props.close}
				style={customStyles}
			>
				<div className="row new-order">
					<div className="col-md-12">
						<NewPromoForm orderInfo={orderInfo} serviceInfo={serviceInfo} />
					</div>
				</div>
			</Modal>
		)
	}
}


const mapStateToProps = state => ({
	business: state.business,
	sources: state.source,
	categories: state.category,
})
const mapDispatchToProps = ({
})
export default connect(mapStateToProps, mapDispatchToProps)(NewPromoModal);
