import React from "react";
import { connect } from "react-redux";
import { updateUserAction } from "../../store/actions/userActions";
import { retrieveFileTempAction } from "../../store/actions/fileActions";

class UserFiles extends React.Component {
	state = {
		error: {},
		isLoading: false,
		title: "",
		rangeFrom: "",
		rangeTo: "",
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	resetFormData = () => {
		this.setState({
			firstName: "",
			lastName: "",
			phone: "",
			email: "",
			password: "",
			address: "",
			address2: "",
			city: "",
			state: "",
			zip: "",
		});
	};

	async componentDidMount() {
		let { userID } = this.props;

		// Get user files
		let userFiles = await this.props.retrieveFileTempAction({
			userID,
		});

		// Set data to state
		this.setState({
			userID,
			userFiles,
		});
	}

	render() {
		let { userFiles } = this.state;

		let allFiles, filePagination, showPagination;
		if (userFiles && userFiles.data) {
			allFiles = userFiles.data.length ? userFiles.data : null;
			filePagination = userFiles.pagination ? userFiles.pagination : null;

			showPagination = filePagination.page_last > 1 ? true : false;
		}

		return !userFiles ? (
			<div>Loading...</div>
		) : (
			<div className={`row`}>
				{allFiles ? (
					allFiles.map((item) => {
						return (
							<div className="col-md-3">
								<img
									className={`img-thumbnail`}
									src={item.path}
									alt={item.title}
								/>
							</div>
						);
					})
				) : (
					<h2 className={`text-center text-info`}>No files</h2>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	updateUserAction,
	retrieveFileTempAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserFiles);
