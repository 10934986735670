import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import bodyClassController from "../../store/utils/bodyClassController";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import PageBreadcrumb from "../../components/default/pageBreadcrumb";

import setPageData from "../../store/actions/setPageData";
import BusinessSideMenu from "../../components/menu/BusinessSideMenu";
import ShowBusinessUsers from "../../components/business/ShowBusinessUsers";
import Icofont from "react-icofont";
import Modal from "react-modal";
import NewBizCardForm from "../../components/business-account/NewBizCardForm";
import { retrieveCardAction } from "../../store/actions/cardActions";
import { retrieveTemplateAction } from "../../store/actions/templateActions";

class BusinessUsersPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: {},
		};
	}

	setPageData() {
		this.props.setPageData({
			title: "Users",
			totalUsers: "...",
			pageID: "users",
		});
	}

	retrieveCompanyCards = async (data) => {
		this.setState({
			dataLoading: true,
		});

		let { page, keyword } = this.state;
		await this.props.retrieveCardAction({
			page,
			keyword,
		});

		this.props.setPageData({
			...this.props.pageData,
			totalUsers: this.props.cardData.pagination.data_total,
		});

		this.setState({
			dataLoading: false,
		});
	};

	retrieveTemplates = async (data) => {
		await this.props.retrieveTemplateAction();
	};

	componentDidMount() {
		this.setPageData();
		this.retrieveTemplates();
		this.retrieveCompanyCards();
	}

	componentWilMount() {}

	openModal() {
		this.setState({
			modalIsOpen: true,
		});
	}
	closeModal() {
		this.setState({
			modalIsOpen: false,
		});
		this.retrieveCompanyCards();
	}

	render() {
		this.props.bodyClassController(this.props.pageData);
		let { pageData } = this.props;

		return (
			<>
				<Helmet>
					{" "}
					<title>{pageData.title}</title>{" "}
				</Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<BusinessSideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{`${pageData.title} (${pageData.totalUsers})`}</h1>
									</div>

									<span
										className="btn btn-primary ml-3"
										onClick={() => this.openModal()}
									>
										<span className="icon-wrapper">
											<Icofont icon="icofont-plus-circle" />
										</span>
										<span className="icon-text">
											{" Add New"}
										</span>
									</span>
									{NewBizAccModal(this)}
								</div>

								<div className="page-content">
									<ShowBusinessUsers
										paymentStatus={`hasPremiumPlan`}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);

		function NewBizAccModal(that) {
			return (
				<Modal
					isOpen={that.state.modalIsOpen}
					contentLabel="Example Modal"
				>
					<div className="basic-modal">
						<div className="modal-header">
							<h3>
								Add new “Digital Card” to your business account.
							</h3>
							<button
								className="btn close-modal"
								type={`button`}
								onClick={() => {
									that.closeModal();
								}}
							>
								<i className="icofont-close-line" />
							</button>
						</div>
						<div className="modal-content">
							<NewBizCardForm />
						</div>
					</div>
				</Modal>
			);
		}
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
	cardData: state.cardData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	retrieveCardAction,
	retrieveTemplateAction,
	bodyClassController,
};
export default connect(mapStateToProps, mapDispatchToProps)(BusinessUsersPage);
