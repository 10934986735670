import axios from "axios";
import * as Types from "./types";

import { manageError } from "../utils/axiosResponseError";
import { showSuccess, showError } from "../utils/configToast";
import { REPORT_AFFILIATE_LIST } from "./types";

export const retrieveReportDetails = (data) => async (dispatch) => {
	let responseData;
	await axios
		.post("/report/affiliate/details", data)
		.then((res) => {
			responseData = res.data;
		})
		.catch((error) => {
			manageError(error);
		});

	return responseData;
};

export const retrieveReport = (data) => (dispatch) => {
	axios
		.post("/report/affiliate/list", data)
		.then((res) => {
			let responseData = res.data;

			dispatch({
				type: Types.REPORT_AFFILIATE_LIST,
				payload: {
					response: responseData,
					error: {},
				},
			});
		})
		.catch((error) => {
			manageError(error);
		});
};

export const retrieveAgentReports = (data) => (dispatch) => {
	axios
		.post("/report/affiliate/my-list", data)
		.then((res) => {
			let responseData = res.data;

			dispatch({
				type: Types.REPORT_AFFILIATE_LIST,
				payload: {
					response: responseData,
					error: {},
				},
			});
		})
		.catch((error) => {
			manageError(error);
		});
};

export const insertReport = (data) => async (dispatch) => {
	let returnType;
	await axios
		.post("/report/affiliate/insert", data)
		.then((res) => {
			let promoInfo = res.data.data;

			showSuccess("Successfully created");

			dispatch({
				type: Types.REPORT_AFFILIATE_INSERT,
				payload: {
					promoInfo,
					error: {},
				},
			});
			dispatch(retrieveReport());
			returnType = true;
		})
		.catch((error) => {
			manageError(error);
		});
	return returnType;
};

export const updateReportDetails = (data) => async (dispatch) => {
	let responseData;
	await axios
		.post("/report/affiliate/update", data)
		.then(async (res) => {
			responseData = res.data.details;
			showSuccess("Successfully updated");
		})
		.catch((error) => {
			manageError(error);
		});
	return responseData;
};
