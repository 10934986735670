import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { resetRequest } from '../../store/actions/authActions'
import bodyClassController from '../../store/utils/bodyClassController'
import setPageData from '../../store/actions/setPageData'

// Style assets
import '../../assets/styles/auth.scss'
import imgLogo from '../../assets/images/logo.png'

class ForgotPasswordPage extends React.Component {
	state = {
		email: '',
		error: {},
		isLoading: false,
	}

	static getDerivedStateFromProps(nextProps, prevProps) {
		if (JSON.stringify(nextProps.auth.error) !== JSON.stringify(prevProps.error)) {
			return {
				error: nextProps.auth.error
			}
		}
		return null
	}

	changeHandler = event => {
		this.setState({
			error: false
		})
		this.setState({
			[event.target.name]: event.target.value
		})
	}

	resetForm = () => {
		this.setState({ email: '' })
	}
	stopLoading = () => {
		this.setState({ isLoading: false })
	}

	submitHandler = event => {
		event.preventDefault()

		this.setState({ isLoading: true })

		let { email } = this.state
		this.props.resetRequest({ email }, this.props.history, this.stopLoading, this.resetForm)
	}

	componentWillMount() {
	}

	redirectHomeIfLoggedin() {
		// Redirect home if already Loggedin
		let { isAuthenticated, authChecking } = this.props.auth
		if (!authChecking && isAuthenticated) {
			this.props.history.push('/')
		}
	}

	componentDidMount() {
		this.props.setPageData({
			title: 'Reset Your Password',
			pageID: 'forgot-password'
		});

	}
	render() {
		this.redirectHomeIfLoggedin()

		this.props.bodyClassController(this.props.pageData);
		let { email, error, isLoading } = this.state
		return (
			<>
				<Helmet> <title>{'Recover Your Password'}</title> </Helmet>

				<div className="container-fluid">
					<div className="row page-auth">
						<div className="col-md-4 page-content">
							<div className="row">
								<div className="col-md-12 login-content">
									<div className="auth-brand">
										<img src={imgLogo} className="logo" alt="Make SMM Panel" />
									</div>

									<p className="text-center">Reset your password</p>
									<form onSubmit={this.submitHandler} className="">
										<div className="form-group has-error">
											<input
												type="text"
												className={
													(error.email ? "form-control is-invalid" : "form-control")
												}
												placeholder="Enter your email here.."
												name="email"
												id="email"
												autoFocus={true}
												tabIndex='1'
												onChange={this.changeHandler}
												value={email}
												required
											/>
											{error.email && (
												<div className="invalid-feedback">
													{error.email}
												</div>
											)}
										</div>

										<div className="form-group form-support">
											<div className="text-muted">
												<Link to="/login" className="">Back to login</Link>
											</div>
										</div>

										<div className="text-right">
											<button
												type={isLoading ? 'button' : 'submit'}
												tabIndex='4'
												className={
													(error.error ? "btn btn-primary mb-2 px-5 btn-block is-invalid" : "btn btn-primary mb-2 px-5 btn-block")
												}> {isLoading ? (<i className="fas fa-spinner fa-spin"></i>) : 'Next'} </button>

											{error.error && (
												<div className="invalid-feedback text-center">
													{error.error}
												</div>
											)}
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
})

const mapDispatchToProps = ({
	resetRequest,
	setPageData,
	bodyClassController
})
// export default connect( mapStateToProps, {login} )(Login)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage))
