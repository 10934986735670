import firebase from "firebase/compat/app";
import "firebase/compat/storage";

// import { getFirestore } from "firebase/firestore";

let firebaseConfig = {
	apiKey: "AIzaSyCSd6rBOSI6ylqI7Olm6OqNTjr5-3Ob_lI",
	authDomain: "nextcard-premium.firebaseapp.com",
	projectId: "nextcard-premium",
	storageBucket: "nextcard-premium.appspot.com",
	messagingSenderId: "1086691712894",
	appId: "1:1086691712894:web:0bb0fb5df817afc30c5d2f",
	measurementId: "G-Y9HGW1P90M",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();
const storage = firebase.storage();

export { storage, firebase as default };
