import React from "react";
import { connect } from "react-redux";
import { insertPromoCodes } from "../../store/actions/promoCodesActions";

class NewPromoForm extends React.Component {
	state = {
		promoCode: "",
		error: {},
		isLoading: false,
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	resetFormData = () => {
		this.setState({
			promoCode: "",
		});
	};

	submitHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		let { promoCode } = this.state;
		await this.props.insertPromoCodes({
			promoCode,
		});
		this.stopLoading();
		this.resetFormData();
	};

	componentDidMount() {
		let { preData } = this.props;

		// set default info if data provided
		if (preData) {
			this.setState({
				promoAuthor: preData.promoAuthor,
			});
		}
	}

	render() {
		let { isLoading, error } = this.state;

		return (
			<form onSubmit={this.submitHandler} action="">
				<div className="row">
					<div className="col-md-12">
						<div className="single-section">
							<div className="form-group has-error">
								<input
									type="text"
									className={
										error.promoCode
											? "form-control is-invalid"
											: "form-control"
									}
									name="promoCode"
									id="promoCode"
									value={this.state.promoCode}
									onChange={this.changeHandler}
									required={true}
									placeholder={`Type New Promo Code Here`}
								/>
								{error.promoCode && (
									<div className="invalid-feedback">
										{error.promoCode}
									</div>
								)}
							</div>

							<div className="form-actions text-right">
								<button
									type={isLoading ? "button" : "submit"}
									className="btn btn-primary is-invalid"
								>
									{" "}
									{isLoading ? (
										<i className="fas fa-spinner fa-spin"></i>
									) : (
										"Add code"
									)}{" "}
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	insertPromoCodes,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewPromoForm);
