import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import bodyClassController from "../../store/utils/bodyClassController";
import PageBreadcrumb from "../../components/default/pageBreadcrumb";

import setPageData from "../../store/actions/setPageData";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import Icofont from "react-icofont";
import "react-confirm-alert/src/react-confirm-alert.css";
import BusinessSideMenu from "../../components/menu/BusinessSideMenu"; // Import css
import {
	insertNewFileAction,
	retrieveFileAction,
} from "../../store/actions/fileActions";
import { storage } from "../../utils/firebase";

class BusinessDashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: {},
			modalIsOpen: false,
		};
	}

	openModal() {
		this.setState({
			modalIsOpen: true,
		});
	}

	closeModal() {
		this.setState({
			modalIsOpen: false,
		});
	}

	setPageData() {
		this.props.setPageData({
			title: "Dashboard",
			pageID: "home_page",
			totalUsers: "...",
		});
	}

	componentDidMount() {
		this.setPageData();
		this.retrieveUserFiles();
	}

	componentWilMount() {}

	// Image retrieve handler
	retrieveUserFiles = async (data) => {
		this.setState({
			dataLoading: true,
		});

		let { page, keyword } = this.state;
		await this.props.retrieveFileAction({
			page,
			keyword,
		});

		this.setState({
			dataLoading: false,
		});
	};
	// Image upload handler
	handleImageAsFile = async (e) => {
		const imageAsFile = e.target.files[0];
		console.log(imageAsFile);
		this.setState({
			imageUploading: true,
		});
		const uploadTask = await storage
			.ref(`/business/${imageAsFile.name}`)
			.put(imageAsFile);
		console.log(uploadTask);
		let getUrl = await storage
			.ref("business")
			.child(imageAsFile.name)
			.getDownloadURL();
		console.log(getUrl);

		// Send file data to server
		if (getUrl) {
			await this.props.insertNewFileAction({
				path: getUrl,
			});
		}

		this.setState({
			imageUploading: false,
		});
	};

	render() {
		this.props.bodyClassController(this.props.pageData);
		let { pageData, fileData } = this.props;
		let { viewUsers } = this.state;

		let { auth } = this.props;
		let { user } = auth;

		let allFiles, filePagination, showPagination;
		if (fileData && fileData.data) {
			allFiles = fileData.data.length ? fileData.data : null;
			filePagination = fileData.pagination ? fileData.pagination : null;

			showPagination = filePagination.page_last > 1 ? true : false;
		}

		return (
			<>
				<Helmet>
					{" "}
					<title>{pageData.title}</title>{" "}
				</Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<BusinessSideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{pageData.title}</h1>
									</div>
								</div>

								<div className="page-content p-4">
									<div>
										<b>Account Details</b>
										<p>
											To make changes to your business
											account please contact
											support@nextcard.me or reach out to
											your sale person.
										</p>
									</div>
									<div className="row">
										<div className="col-md-4">
											<table className={``}>
												<tr>
													<td>Business Name:</td>
													<td>{user.U_FirstName}</td>
												</tr>
												<tr>
													<td>Business Address 1:</td>
													<td>{user.U_AddressA}</td>
												</tr>
												<tr>
													<td>Business Address 2:</td>
													<td>{user.U_AddressA}</td>
												</tr>
												<tr>
													<td>City:</td>
													<td>{user.U_City}</td>
												</tr>
												<tr>
													<td>State:</td>
													<td>{user.U_State}</td>
												</tr>
												<tr>
													<td>Zip:</td>
													<td>{user.U_PostCode}</td>
												</tr>
												<tr>
													<td>Phone:</td>
													<td>{user.U_Phone}</td>
												</tr>
											</table>
										</div>
										<div className="col-md-4">
											<table className={``}>
												<tr>
													<td>Contact Name:</td>
													<td>{user.U_LastName}</td>
												</tr>
												<tr>
													<td>Contact Phone:</td>
													<td>{user.U_Phone}</td>
												</tr>
												<tr>
													<td>Contact Email:</td>
													<td>{user.U_Email}</td>
												</tr>
											</table>
										</div>

										<div className="col-md-4">
											<table className={``}>
												<tr>
													<td>Customer ID:</td>
													<td>{user.UserID}</td>
												</tr>
												<tr>
													<td>Number of Users:</td>
													<td>{`${user.total_cards}/${user.cardLimit}`}</td>
												</tr>
												<tr>
													<td>
														Number of Templates:
													</td>
													<td>
														{user.totalTemplates}
													</td>
												</tr>
											</table>
										</div>
									</div>

									<hr />

									<div>
										<b>Upload Artwork</b>
										<input
											id={`uploadImage`}
											type="file"
											onChange={this.handleImageAsFile}
											className={`d-none`}
										/>
										{this.state.imageUploading === true ? (
											<span>Uploading...</span>
										) : (
											<label
												className="btn color-primary"
												onClick={() => this.openModal()}
												htmlFor={`uploadImage`}
											>
												<span className="icon-wrapper">
													<Icofont icon="icofont-upload-alt" />
												</span>
											</label>
										)}

										<div className="row">
											{allFiles
												? allFiles.map((item) => {
														return (
															<div className="col-md-3">
																<img
																	className={`img-thumbnail`}
																	src={
																		item.path
																	}
																	alt={
																		item.title
																	}
																/>
															</div>
														);
												  })
												: ""}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
	fileData: state.fileData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	bodyClassController,
	insertNewFileAction,
	retrieveFileAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(BusinessDashboard);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
