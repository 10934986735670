import React from "react";
import { connect } from "react-redux";
import { insertPromoCodes } from "../../store/actions/promoCodesActions";
import { insertReport } from "../../store/actions/reportsActions";
import {
	registerBusiness,
	retrieveUsersTemp,
} from "../../store/actions/userActions";
import AsyncSelect from "react-select/async/dist/react-select.esm";

class NewBizAccountForm extends React.Component {
	state = {
		error: {},
		isLoading: false,
		title: "",
		rangeFrom: "",
		rangeTo: "",
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	getAgentsAsOptions = async (inputValue) => {
		let allUsers = await this.props.retrieveUsersTemp({
			keyword: inputValue,
			affiliate: true,
		});

		this.setState({
			allUsers: allUsers.data,
		});

		let options = [];

		for (const i of allUsers.data) {
			let userDisplayName = i.name
				? i.name
				: `${i.firstName} ${i.lastName}`;
			userDisplayName = `${userDisplayName} | ${i.email}`;

			options.push({ value: i.UserID, label: userDisplayName });
		}

		return options;
	};

	handleAgentChange = (newValue, actionMeta) => {
		console.group("Value Changed");
		console.log(newValue.value);
		this.setState({
			userID: newValue.value,
		});

		// Set selected user
		let { allUsers } = this.state;
		for (const i of allUsers) {
			if (i.UserID === newValue.value) {
				this.setState({
					selectedAgent: i.UserID,
				});
				console.log("selected user founded");
			}
		}

		console.log(`action: ${actionMeta.action}`);
		console.groupEnd();
	};

	resetFormData = () => {
		this.setState({
			firstName: "",
			lastName: "",
			phone: "",
			email: "",
			password: "",
			address: "",
			address2: "",
			city: "",
			state: "",
			zip: "",
			selectedAgent: "",
		});
	};

	submitHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		let {
			firstName,
			lastName,
			phone,
			email,
			password,
			address,
			address2,
			city,
			state,
			zip,
		} = this.state;
		let insertBusiness = await this.props.registerBusiness({
			firstName,
			lastName,
			phone,
			email,
			password,
			confirmPassword: password,
			address,
			address2,
			city,
			state,
			zip,
			referBy: this.state.selectedAgent,
		});
		this.stopLoading();
		if (insertBusiness === true) {
			this.resetFormData();
		}
	};

	componentDidMount() {
		let { preData } = this.props;

		// set default info if data provided
		if (preData) {
			this.setState({
				promoAuthor: preData.promoAuthor,
			});
		}
	}

	render() {
		let { isLoading, error } = this.state;

		return (
			<form onSubmit={this.submitHandler} action="">
				<div className="row insert-new">
					<div className="col-md-6">
						<div className="single-section">
							<div className="section-title">
								<h4>Business info</h4>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="firstName">
											Business Name
										</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.title
													? "form-control is-invalid"
													: "form-control"
											}
											name="firstName"
											id="firstName"
											value={this.state.firstName}
											onChange={this.changeHandler}
											required={true}
											placeholder={`Ex. NextCard`}
										/>
										{error.firstName && (
											<div className="invalid-feedback">
												{error.firstName}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="address">Address</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.address
													? "form-control is-invalid"
													: "form-control"
											}
											name="address"
											id="address"
											value={this.state.address}
											onChange={this.changeHandler}
											required={true}
											placeholder={``}
										/>
										{error.address && (
											<div className="invalid-feedback">
												{error.address}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="address2">
											Address 2
										</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.address2
													? "form-control is-invalid"
													: "form-control"
											}
											name="address2"
											id="address2"
											value={this.state.address2}
											onChange={this.changeHandler}
											placeholder={``}
										/>
										{error.address2 && (
											<div className="invalid-feedback">
												{error.address2}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="city">City</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.city
													? "form-control is-invalid"
													: "form-control"
											}
											name="city"
											id="city"
											value={this.state.city}
											onChange={this.changeHandler}
											placeholder={``}
										/>
										{error.city && (
											<div className="invalid-feedback">
												{error.city}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="state">State</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.state
													? "form-control is-invalid"
													: "form-control"
											}
											name="state"
											id="state"
											value={this.state.state}
											onChange={this.changeHandler}
											placeholder={``}
										/>
										{error.state && (
											<div className="invalid-feedback">
												{error.state}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="zip">Zip</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.zip
													? "form-control is-invalid"
													: "form-control"
											}
											name="zip"
											id="zip"
											value={this.state.zip}
											onChange={this.changeHandler}
											placeholder={``}
										/>
										{error.zip && (
											<div className="invalid-feedback">
												{error.zip}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="single-section">
							<div className="section-title">
								<h4>Affiliate Info</h4>
							</div>

							<div className="single-section">
								<div className="form-group has-error">
									<div className="row">
										<div className="col-md-4">
											<label htmlFor="lastName">
												Agent
											</label>
										</div>
										<div className="col-md-8">
											<AsyncSelect
												cacheOptions
												defaultOptions
												placeholder={`Select agent...`}
												loadOptions={
													this.getAgentsAsOptions
												}
												onChange={
													this.handleAgentChange
												}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="single-section">
							<div className="section-title">
								<h4>Admin info</h4>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="lastName">Name</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.lastName
													? "form-control is-invalid"
													: "form-control"
											}
											name="lastName"
											id="lastName"
											value={this.state.lastName}
											onChange={this.changeHandler}
											required={true}
											placeholder={``}
										/>
										{error.lastName && (
											<div className="invalid-feedback">
												{error.lastName}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="phone">Phone</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.phone
													? "form-control is-invalid"
													: "form-control"
											}
											name="phone"
											id="phone"
											value={this.state.phone}
											onChange={this.changeHandler}
											required={true}
											placeholder={``}
										/>
										{error.phone && (
											<div className="invalid-feedback">
												{error.phone}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="email">Email</label>
									</div>
									<div className="col-md-8">
										<input
											type="email"
											className={
												error.email
													? "form-control is-invalid"
													: "form-control"
											}
											name="email"
											id="email"
											value={this.state.email}
											onChange={this.changeHandler}
											required={true}
											placeholder={``}
										/>
										{error.email && (
											<div className="invalid-feedback">
												{error.email}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="password">
											Password
										</label>
									</div>
									<div className="col-md-8">
										<input
											type="text"
											className={
												error.password
													? "form-control is-invalid"
													: "form-control"
											}
											name="password"
											id="password"
											value={this.state.password}
											onChange={this.changeHandler}
											required={true}
											placeholder={``}
										/>
										{error.password && (
											<div className="invalid-feedback">
												{error.password}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12">
						<div className="single-section">
							<div className="form-actions text-right">
								<button
									type={isLoading ? "button" : "submit"}
									className="btn btn-primary is-invalid"
								>
									{" "}
									{isLoading ? (
										<i className="fas fa-spinner fa-spin"></i>
									) : (
										"Create Account"
									)}{" "}
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	registerBusiness,
	retrieveUsersTemp,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewBizAccountForm);
