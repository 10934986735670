import React from "react";
import { connect } from "react-redux";
import { updateUserAction } from "../../store/actions/userActions";
import { retrieveFileTempAction } from "../../store/actions/fileActions";
import { retrieveTemplateAction } from "../../store/actions/templateActions";
import { ViewExactDate } from "../../utils/DateViewer";
import ReactPaginate from "react-paginate";

class UserTemplates extends React.Component {
	state = {
		error: {},
		isLoading: false,
		title: "",
		rangeFrom: "",
		rangeTo: "",
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	resetFormData = () => {
		this.setState({
			firstName: "",
			lastName: "",
			phone: "",
			email: "",
			password: "",
			address: "",
			address2: "",
			city: "",
			state: "",
			zip: "",
		});
	};

	async componentDidMount() {
		let { userID } = this.props;

		// Get user files
		let userTemplates = await this.props.retrieveTemplateAction({
			userID,
		});

		// Set data to state
		this.setState({
			userID,
			userTemplates,
		});
	}

	render() {
		let { userTemplates } = this.state;

		let allTemplates, filePagination, showPagination;
		if (userTemplates && userTemplates.data) {
			allTemplates = userTemplates.data.length
				? userTemplates.data
				: null;
			filePagination = userTemplates.pagination
				? userTemplates.pagination
				: null;

			showPagination = filePagination.page_last > 1 ? true : false;
		}

		return !userTemplates ? (
			<div>Loading...</div>
		) : (
			<div className={`row`}>
				<table className={`table`}>
					{allTemplates ? (
						allTemplates.map((item) => {
							return (
								<tr>
									<td>{item.title}</td>
									<td>
										<a
											href={`https://nextcard.me/${item.cardID}`}
											target={`_blank`}
										>
											{item.cardID}
										</a>
									</td>
								</tr>
							);
						})
					) : (
						<>
							<h2 className={`text-center text-info mt-4`}>
								No templates
							</h2>
							<p className={`text-center`}>
								Assign template from App
							</p>
						</>
					)}
				</table>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	updateUserAction,
	retrieveTemplateAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserTemplates);
