import * as Types from "../actions/types";

const init = {
	data: {},
	pagination: {},
	error: {},
};

const templateReducer = (state = init, action) => {
	switch (action.type) {
		case Types.TEMPLATE_RETRIEVE: {
			return {
				...state,
				data: action.payload.response.data,
				pagination: action.payload.response.pagination,
			};
		}
		default:
			return state;
	}
};

export default templateReducer;
