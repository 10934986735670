import React from "react";
import { connect } from "react-redux";
import { insertPromoCodes } from "../../store/actions/promoCodesActions";
import { insertReport } from "../../store/actions/reportsActions";

class NewReportForm extends React.Component {
	state = {
		error: {},
		isLoading: false,
		title: "",
		rangeFrom: "",
		rangeTo: "",
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	resetFormData = () => {
		this.setState({
			title: "",
			rangeFrom: "",
			rangeTo: "",
		});
	};

	submitHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		let { title, rangeFrom, rangeTo } = this.state;
		let insertReport = await this.props.insertReport({
			title,
			rangeFrom,
			rangeTo,
		});
		this.stopLoading();
		if (insertReport === true) {
			this.resetFormData();
		}
	};

	componentDidMount() {
		let { preData } = this.props;

		// set default info if data provided
		if (preData) {
			this.setState({
				promoAuthor: preData.promoAuthor,
			});
		}
	}

	render() {
		let { isLoading, error } = this.state;

		return (
			<form onSubmit={this.submitHandler} action="">
				<div className="row">
					<div className="col-md-12">
						<div className="single-section">
							<div className="form-group has-error">
								<label htmlFor="title">Report Title</label>
								<input
									type="text"
									className={
										error.title
											? "form-control is-invalid"
											: "form-control"
									}
									name="title"
									id="title"
									value={this.state.title}
									onChange={this.changeHandler}
									required={true}
									placeholder={`January Sales`}
								/>
								{error.title && (
									<div className="invalid-feedback">
										{error.title}
									</div>
								)}
							</div>

							<div className="form-group has-error">
								<label htmlFor="rangeFrom">End Date</label>
								<input
									type="date"
									className={
										error.promoCode
											? "form-control is-invalid"
											: "form-control"
									}
									name="rangeFrom"
									id="rangeFrom"
									value={this.state.rangeFrom}
									onChange={this.changeHandler}
									required={true}
									placeholder={`01/31/201`}
								/>
								{error.promoCode && (
									<div className="invalid-feedback">
										{error.rangeFrom}
									</div>
								)}
							</div>

							<div className="form-group has-error">
								<label htmlFor="rangeTo">Start Date</label>
								<input
									type="date"
									className={
										error.rangeTo
											? "form-control is-invalid"
											: "form-control"
									}
									name="rangeTo"
									id="rangeTo"
									value={this.state.rangeTo}
									onChange={this.changeHandler}
									required={true}
									placeholder={`01/01/2021`}
								/>
								{error.rangeTo && (
									<div className="invalid-feedback">
										{error.rangeTo}
									</div>
								)}
							</div>

							<div className="form-actions text-right">
								<button
									type={isLoading ? "button" : "submit"}
									className="btn btn-primary is-invalid"
								>
									{" "}
									{isLoading ? (
										<i className="fas fa-spinner fa-spin"></i>
									) : (
										"Create report"
									)}{" "}
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	insertPromoCodes,
	insertReport,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewReportForm);
