import * as Types from "../actions/types";

const init = {
	data: {},
	pagination: {},
	error: {},
};

const prospectorReducer = (state = init, action) => {
	switch (action.type) {
		case Types.PROSPECT_RETRIEVE: {
			return {
				...state,
				data: action.payload.response.data,
				pagination: action.payload.response.pagination,
			};
		}

		default:
			return state;
	}
};

export default prospectorReducer;
