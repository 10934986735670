import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import Icofont from "react-icofont";

class SideMenu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: "",
			password: "",
			error: {},
		};
	}

	changeHandler = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	submitHandler = (event) => {
		event.preventDefault();

		this.props.logout(this.props.history);
	};

	render() {
		let { pageID } = this.props.pageData;
		let { user } = this.props.auth;
		return (
			<div className="col-md-2 side-menu">
				<nav className="collapse show sidebar-nav pt-4" id="left-nav">
					<div
						className={
							pageID === "home_page"
								? "nav-item active"
								: "nav-item"
						}
					>
						<Link to="/" className="nav-link">
							<span className="icon-text">Home</span>
						</Link>
					</div>

					<div
						className={
							pageID === "users_trial"
								? "nav-item active"
								: "nav-item"
						}
					>
						<Link to="/users/trial" className="nav-link">
							<span className="icon-text">Trial</span>
						</Link>
					</div>
					<div
						className={
							pageID === "users_free"
								? "nav-item active"
								: "nav-item"
						}
					>
						<Link to="/users/free" className="nav-link">
							<span className="icon-text">Free</span>
						</Link>
					</div>
					<div
						className={
							pageID === "users_premium"
								? "nav-item active"
								: "nav-item"
						}
					>
						<Link to="/users/premium" className="nav-link">
							<span className="icon-text">Premium</span>
						</Link>
					</div>
					<div
						className={
							pageID === "users_business"
								? "nav-item active"
								: "nav-item"
						}
					>
						<Link to="/users/business" className="nav-link">
							<span className="icon-text">Business Acc</span>
						</Link>
					</div>
					<div
						className={
							pageID === "users_cancelled"
								? "nav-item active"
								: "nav-item"
						}
					>
						<Link to="/users/cancelled" className="nav-link">
							<span className="icon-text">Canceled</span>
						</Link>
					</div>
					<div
						className={
							pageID === "users_inactive"
								? "nav-item active"
								: "nav-item"
						}
					>
						<Link to="/users/inactive" className="nav-link">
							<span className="icon-text">Inactive</span>
						</Link>
					</div>

					<div
						className={
							pageID === "affiliates"
								? "nav-item active"
								: "nav-item"
						}
					>
						<Link to="/affiliates" className="nav-link">
							<span className="icon-text">Affiliates</span>
						</Link>
					</div>

					<div
						className={
							pageID === "send_notification"
								? "nav-item active"
								: "nav-item"
						}
					>
						<Link to="/notification" className="nav-link">
							<span className="icon-text">Notification</span>
						</Link>
					</div>

					<div
						className={
							pageID === "reports"
								? "nav-item active"
								: "nav-item"
						}
					>
						<Link to="/reports" className="nav-link">
							<span className="icon-text">Reports</span>
						</Link>
					</div>

					{user.U_Type === "admin" && user.affiliateStatus === "1" ? (
						<div
							className={`nav-item`}
							onClick={() => {
								localStorage.setItem("viewAsAgent", "1");
								window.location.replace("/");
							}}
						>
							<div className="nav-link">
								<span className="icon-text">Agent Portal</span>
							</div>
						</div>
					) : (
						""
					)}
				</nav>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	logout,
};
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SideMenu)
);
