import React from "react";
import { connect } from "react-redux";
import { insertNewCardAction } from "../../store/actions/cardActions";
// import {insertNewCardAction} from "../../store/actions/cardActions";

class NewBizCardForm extends React.Component {
	state = {
		error: {},
		isLoading: false,
		title: "",
		rangeFrom: "",
		rangeTo: "",
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	resetFormData = () => {
		this.setState({
			name: "",
			title: "",
			email: "",
			address: "",
			phone: "",
			text: "",
			customID: "",
		});
	};

	submitHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		let { name, title, email, address, phone, text, customID, template } =
			this.state;
		let insertBusiness = await this.props.insertNewCardAction({
			name,
			title,
			email,
			address,
			phone,
			text,
			customID,
			template,
		});
		this.stopLoading();
		if (insertBusiness) {
			this.resetFormData();
		}
	};

	componentDidMount() {
		let { preData } = this.props;

		// set default info if data provided
		if (preData) {
			this.setState({
				promoAuthor: preData.promoAuthor,
			});
		}
	}

	render() {
		let { isLoading, error } = this.state;

		// getting template data
		let { templateData } = this.props;
		let allTemplates;
		if (
			templateData &&
			templateData.data &&
			templateData.pagination.data_total > 0
		) {
			allTemplates = templateData.data.length ? templateData.data : null;
		}

		return (
			<form onSubmit={this.submitHandler} action="">
				<div className="row insert-new">
					<div className="col-md-6">
						<div className="single-section">
							<div className="section-title">
								<h5>
									Create custom web URL for this employee.
									This will be where the card is stored on the
									web.
								</h5>
							</div>
							<div className="form-group has-error">
								<input
									type="text"
									className={
										error.customID
											? "form-control is-invalid"
											: "form-control"
									}
									name="customID"
									id="customID"
									value={this.state.customID}
									onChange={this.changeHandler}
									placeholder={`Enter custom (Ex. nextcard)`}
								/>
								{error.customID && (
									<div className="invalid-feedback">
										{error.customID}
									</div>
								)}
							</div>
							<p>
								{`Creating a custom url will make it easier for people to share or
remember their information. We suggest using  <first name>
<last name> as a easy way to get started.`}
							</p>
							<p>
								{`example weblink: `}
								<a
									href="https://nextcard.me/jasonmcwhorter"
									target={`_blank`}
								>
									https://nextcard.me/jasonmcwhorter
								</a>
							</p>
						</div>
					</div>
					<div className="col-md-6">
						<div className="single-section">
							<div className="section-title">
								<h4>
									This information will be used on digital
									card.
								</h4>
							</div>

							<div className="form-group has-error">
								<select
									name="template"
									value={this.state.template}
									onChange={this.changeHandler}
									required={true}
									className={
										error.template
											? "form-control is-invalid"
											: "form-control"
									}
									id="template"
								>
									<option value="">Select template</option>

									{allTemplates
										? allTemplates.map((item) => {
												return (
													<option value={item.id}>
														{item.title}
													</option>
												);
										  })
										: ""}
								</select>
								{error.template && (
									<div className="invalid-feedback">
										{error.template}
									</div>
								)}
							</div>
							<div className="form-group has-error">
								<input
									type="text"
									className={
										error.name
											? "form-control is-invalid"
											: "form-control"
									}
									name="name"
									id="name"
									value={this.state.name}
									onChange={this.changeHandler}
									required={true}
									placeholder={`Name on Card`}
								/>
								{error.name && (
									<div className="invalid-feedback">
										{error.name}
									</div>
								)}
							</div>
							<div className="form-group has-error">
								<input
									type="text"
									className={
										error.title
											? "form-control is-invalid"
											: "form-control"
									}
									name="title"
									id="title"
									value={this.state.title}
									onChange={this.changeHandler}
									required={true}
									placeholder={`Position`}
								/>
								{error.title && (
									<div className="invalid-feedback">
										{error.title}
									</div>
								)}
							</div>
							<div className="form-group has-error">
								<input
									type="text"
									className={
										error.phone
											? "form-control is-invalid"
											: "form-control"
									}
									name="phone"
									id="phone"
									value={this.state.phone}
									onChange={this.changeHandler}
									required={true}
									placeholder={`Main Calling Number`}
								/>
								{error.phone && (
									<div className="invalid-feedback">
										{error.phone}
									</div>
								)}
							</div>
							<div className="form-group has-error">
								<input
									type="text"
									className={
										error.text
											? "form-control is-invalid"
											: "form-control"
									}
									name="text"
									id="text"
									value={this.state.text}
									onChange={this.changeHandler}
									required={true}
									placeholder={`Main Texting Number `}
								/>
								{error.text && (
									<div className="invalid-feedback">
										{error.text}
									</div>
								)}
							</div>
							<div className="form-group has-error">
								<input
									type="text"
									className={
										error.email
											? "form-control is-invalid"
											: "form-control"
									}
									name="email"
									id="email"
									value={this.state.email}
									onChange={this.changeHandler}
									required={true}
									placeholder={`Email`}
								/>
								{error.email && (
									<div className="invalid-feedback">
										{error.email}
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="col-md-12">
						<div className="single-section">
							<div className=" text-right">
								<button
									type={isLoading ? "button" : "submit"}
									className="btn btn-primary is-invalid"
								>
									{" "}
									{isLoading ? (
										<i className="fas fa-spinner fa-spin" />
									) : (
										"Add Digital Card"
									)}{" "}
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	templateData: state.templateData,
});
const mapDispatchToProps = {
	insertNewCardAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewBizCardForm);
