import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import LoginPage from "./pages/auth/LoginPage";

import UsersPage from "./pages/user/UsersPage";
import PromoCodesPage from "./pages/promo-code/PromoCodesPage";
import NewPromoCodePage from "./pages/promo-code/NewPromoCodePage";

import AuthChecking from "./components/default/authChecking";

import { connect } from "react-redux";

import { isLoggedin, setViewMode } from "./store/actions/authActions";

import configureAxios from "./store/utils/configureAxios";

import PrimaryMenu from "./components/menu/headerPrimaryMenu";
import FooterPrimary from "./components/footer/footerPrimary";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";
import ResetPasswordPage from "./pages/auth/ResetPasswordPage";
import AffiliatesPage from "./pages/affiliate/AffiliatesPage";
import NewAffiliatePage from "./pages/affiliate/NewAffiliatePage";
import UserDetailsPage from "./pages/user/UserDetailsPage";
import UserUpdatePage from "./pages/user/UserUpdatePage";
import UsersFreePage from "./pages/user/UsersFreePage";
import HomePage from "./pages/HomePage";
import UsersAllPage from "./pages/user/UsersAllPage";
import UsersPremiumPage from "./pages/user/UsersPremiumPage";
import UsersCancelledPage from "./pages/user/UsersCancelledPage";
import UsersTrialPage from "./pages/user/UsersTrialPage";
import AffiliateDashboard from "./pages/affiliate/AffiliateDashboard";
import AffiliatesUsersAll from "./pages/affiliate/AffiliatesUsersAll";
import AffiliateUsersCancelledPage from "./pages/affiliate_users/UsersCancelledPage";
import AffiliateUsersFreePage from "./pages/affiliate_users/UsersFreePage";
import AffiliateUsersTrialPage from "./pages/affiliate_users/UsersTrialPage";
import AffiliateUsersPremiumPage from "./pages/affiliate_users/UsersPremiumPage";
import ReportsPage from "./pages/report/ReportsPage";
import UsersInActivePage from "./pages/user/UsersInActivePage";
import ReportDetailsPage from "./pages/report/ReportDetailsPage";
import UsersInactivePage from "./pages/affiliate_users/UsersInactivePage";
import UsersBusinessPage from "./pages/user/UsersBusinessPage";
import BusinessDashboard from "./pages/business/BusinessDashboard";
import BusinessUsersPage from "./pages/business/BusinessUsersPage";
import BusinessProspectsPage from "./pages/business/BusinessProspectsPage";
import BusinessTemplatePage from "./pages/business/BusinessTemplatePage";
import AffiliateUsersBusinessPage from "./pages/affiliate_users/UsersBusinessPage";
import NewNotificationPage from "./pages/notification/NewNotificationPage";

class Routes extends React.Component {
	state = {
		user: "",
		viewMode: null,
		password: "",
		error: {},
	};
	async componentWillMount() {
		configureAxios(); // Run Basic Axios Configure
	}

	componentDidMount() {}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			JSON.stringify(prevProps.auth) !== JSON.stringify(this.props.auth)
		) {
			let viewAsAgent = localStorage.getItem("viewAsAgent");

			let { user } = this.props.auth;

			let viewMode;

			if (user && user.U_Type === "admin" && viewAsAgent === "1") {
				viewMode = "affiliate";
			} else if (user && user.U_Type === "admin") {
				viewMode = "admin";
			} else if (user && user.affiliateStatus === "1") {
				viewMode = "affiliate";
			} else if (user && user.businessAccount === "1") {
				viewMode = "business";
			}
			// this.setState({
			// 	viewMode,
			// });
			this.props.setViewMode(viewMode);
		}
	}

	render() {
		let { isAuthenticated } = this.props.auth;
		let { viewMode } = this.props.auth;

		if (viewMode === "affiliate") {
			document.documentElement.style.setProperty(
				"--primaryColor",
				`#39E46E`
			);
			document.documentElement.style.setProperty(
				"--secondaryColor",
				`#24ac4e`
			);
		} else if (viewMode === "business") {
			document.documentElement.style.setProperty(
				"--primaryColor",
				`#E4AF39`
			);
			document.documentElement.style.setProperty(
				"--secondaryColor",
				`#dba01f`
			);
		}

		return (
			<BrowserRouter>
				<Switch>
					<Route path="/login" component={LoginPage} />
					<Route
						path="/forgot-password"
						component={ForgotPasswordPage}
					/>
					<Route
						path="/reset-password"
						component={ResetPasswordPage}
					/>

					{(() => {
						if (viewMode === "admin") {
							return (
								<Switch>
									<Route
										path="/"
										exact
										component={
											isAuthenticated
												? UsersAllPage
												: AuthChecking
										}
									/>
									<Route
										path="/users"
										exact
										component={
											isAuthenticated
												? UsersAllPage
												: AuthChecking
										}
									/>
									<Route
										path="/users/free"
										exact
										component={
											isAuthenticated
												? UsersFreePage
												: AuthChecking
										}
									/>
									<Route
										path="/users/trial"
										exact
										component={
											isAuthenticated
												? UsersTrialPage
												: AuthChecking
										}
									/>
									<Route
										path="/users/premium"
										exact
										component={
											isAuthenticated
												? UsersPremiumPage
												: AuthChecking
										}
									/>
									<Route
										path="/users/business"
										exact
										component={
											isAuthenticated
												? UsersBusinessPage
												: AuthChecking
										}
									/>
									<Route
										path="/users/cancelled"
										exact
										component={
											isAuthenticated
												? UsersCancelledPage
												: AuthChecking
										}
									/>
									<Route
										path="/users/inactive"
										exact
										component={
											isAuthenticated
												? UsersInActivePage
												: AuthChecking
										}
									/>

									<Route
										path="/users/:UserID"
										exact
										component={
											isAuthenticated
												? UserDetailsPage
												: AuthChecking
										}
									/>
									<Route
										path="/users/:UserID/update"
										exact
										component={
											isAuthenticated
												? UserUpdatePage
												: AuthChecking
										}
									/>

									<Route
										path="/reports"
										exact
										component={
											isAuthenticated
												? ReportsPage
												: AuthChecking
										}
									/>
									<Route
										path="/notification"
										exact
										component={
											isAuthenticated
												? NewNotificationPage
												: AuthChecking
										}
									/>
									<Route
										path="/reports/:reportID"
										exact
										component={
											isAuthenticated
												? ReportDetailsPage
												: AuthChecking
										}
									/>

									<Route
										path="/affiliates"
										exact
										component={
											isAuthenticated
												? AffiliatesPage
												: AuthChecking
										}
									/>
									<Route
										path="/affiliates/new"
										exact
										component={
											isAuthenticated
												? NewAffiliatePage
												: AuthChecking
										}
									/>
								</Switch>
							);
						} else if (viewMode === "affiliate") {
							return (
								<>
									<Route
										path="/"
										exact
										component={
											isAuthenticated
												? AffiliateDashboard
												: AuthChecking
										}
									/>
									<Route
										path="/users"
										exact
										component={
											isAuthenticated
												? AffiliateUsersPremiumPage
												: AuthChecking
										}
									/>
									<Route
										path="/users/trial"
										exact
										component={
											isAuthenticated
												? AffiliateUsersTrialPage
												: AuthChecking
										}
									/>
									<Route
										path="/users/free"
										exact
										component={
											isAuthenticated
												? AffiliateUsersFreePage
												: AuthChecking
										}
									/>
									<Route
										path="/users/premium"
										exact
										component={
											isAuthenticated
												? AffiliateUsersPremiumPage
												: AuthChecking
										}
									/>
									<Route
										path="/users/business"
										exact
										component={
											isAuthenticated
												? AffiliateUsersBusinessPage
												: AuthChecking
										}
									/>
									<Route
										path="/users/cancelled"
										exact
										component={
											isAuthenticated
												? AffiliateUsersCancelledPage
												: AuthChecking
										}
									/>
									<Route
										path="/users/inactive"
										exact
										component={
											isAuthenticated
												? UsersInactivePage
												: AuthChecking
										}
									/>
								</>
							);
						} else if (viewMode === "business") {
							return (
								<>
									<Route
										path="/"
										exact
										component={
											isAuthenticated
												? BusinessDashboard
												: AuthChecking
										}
									/>
									<Route
										path="/users"
										exact
										component={
											isAuthenticated
												? BusinessUsersPage
												: AuthChecking
										}
									/>
									<Route
										path="/templates"
										exact
										component={
											isAuthenticated
												? BusinessTemplatePage
												: AuthChecking
										}
									/>
									<Route
										path="/prospects"
										exact
										component={
											isAuthenticated
												? BusinessProspectsPage
												: AuthChecking
										}
									/>
								</>
							);
						} else {
							return (
								<Route
									path="/"
									// exact
									component={AuthChecking}
								/>
							);
						}
					})()}

					{/*<Route*/}
					{/*	path="/promo-code"*/}
					{/*	exact*/}
					{/*	component={*/}
					{/*		isAuthenticated ? PromoCodesPage : AuthChecking*/}
					{/*	}*/}
					{/*/>*/}
					{/*<Route*/}
					{/*	path="/promo-code/new"*/}
					{/*	exact*/}
					{/*	component={*/}
					{/*		isAuthenticated ? NewPromoCodePage : AuthChecking*/}
					{/*	}*/}
					{/*/>*/}
				</Switch>
			</BrowserRouter>
		);
	}
}

// export default App;

const mapStateToProps = (state) => ({
	auth: state.auth,
});
const mapDispatchToProps = {
	isLoggedin,
	PrimaryMenu,
	FooterPrimary,
	setViewMode,
};
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
