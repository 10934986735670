import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";
import ReactPaginate from "react-paginate";
import Icofont from "react-icofont";

import bodyClassController from "../../store/utils/bodyClassController";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import SideMenu from "../../components/menu/sideMenu";
import PageBreadcrumb from "../../components/default/pageBreadcrumb";

import setPageData from "../../store/actions/setPageData";
import { retrieveAffiliates } from "../../store/actions/userActions";
import Modal from "react-modal";
import NewReportForm from "../../components/report/NewReportForm";
import AffiliatePreview from "../../components/affiliate/AffiliatePreview";

class AffiliatesPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ReOrderModalOpen: null,
			expandedItem: null,
			NewTicketModalOpen: false,
			user: "",
			password: "",
			affiliate: true,
			keyword: "",
			error: {},
			page: 1,
		};
	}
	changeHandler = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	submitHandler = (event) => {
		if (event != null) {
			event.preventDefault();
		}

		let { page, keyword, affiliate } = this.state;

		this.props.retrieveAffiliates({
			page,
			keyword,
			affiliate,
			viewType: "affiliate",
		});
	};

	setPageData() {
		this.props.setPageData({
			title: "Affiliates",
			pageID: "affiliates",
		});
	}

	componentDidMount() {
		this.setPageData();
		this.submitHandler();
	}

	componentWilMount() {}

	handlePagination = (data) => {
		this.setState(
			{
				page: data.selected + 1,
			},
			() => {
				this.submitHandler();
			}
		);
	};

	render() {
		this.props.bodyClassController(this.props.pageData);

		let { affiliateData, pageData } = this.props;
		let { keyword, expandedItem } = this.state;
		let allUsers, userPagination, showPagination;
		if (affiliateData && affiliateData.data) {
			allUsers = affiliateData.data.length ? affiliateData.data : null;
			userPagination = affiliateData.pagination
				? affiliateData.pagination
				: null;

			showPagination = userPagination.page_last > 1 ? true : false;
		}

		return (
			<>
				<Helmet>
					{" "}
					<title>{pageData.title}</title>{" "}
				</Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body page-affiliates">
						<SideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{pageData.title}</h1>
									</div>
									<Link
										to="/affiliates/new"
										className="btn btn-primary ml-3"
									>
										<span className="icon-wrapper">
											<Icofont icon="icofont-plus-circle" />
										</span>
										<span className="icon-text">
											Add New
										</span>
									</Link>
								</div>

								<div className="page-content">
									<div className="table-area table-responsive">
										<div className="data-viewer data-affiliates-admin">
											<div className="viewer-header">
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Name
														</div>
														<div className="item-search">
															<input
																type="text"
																placeholder={`Search...`}
															/>
														</div>
													</div>

													<div className="item-action">
														<div className="action-search">
															<i className="fa icofont-search-2 "></i>
														</div>
														<div className="action-sort">
															<i className="fa fa-sort-amount-up"></i>
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Trail
														</div>
													</div>

													<div className="item-action">
														<div className="action-sort">
															<i className="fa fa-sort-amount-up"></i>
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Free
														</div>
													</div>

													<div className="item-action">
														<div className="action-sort">
															<i className="fa fa-sort-amount-up"></i>
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Premium
														</div>
													</div>

													<div className="item-action">
														<div className="action-sort">
															<i className="fa fa-sort-amount-up"></i>
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Enterprise
														</div>
													</div>

													<div className="item-action">
														<div className="action-sort">
															<i className="fa fa-sort-amount-up"></i>
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Business
														</div>
													</div>

													<div className="item-action">
														<div className="action-sort">
															<i className="fa fa-sort-amount-up"></i>
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Total Paid
														</div>
													</div>

													<div className="item-action">
														<div className="action-sort">
															<i className="fa fa-sort-amount-up"></i>
														</div>
													</div>
												</div>
											</div>

											<div className="viewer-content">
												{allUsers
													? allUsers.map((item) => {
															let userDisplayName =
																item.name
																	? item.name
																	: `${item.firstName} ${item.lastName}`;

															let refDisplayName =
																item.referBy
																	? `${item.refFirstName} ${item.refLastName}`
																	: "--";
															let promoCode =
																item.promoCode
																	? item.promoCode
																	: "--";
															return (
																<div className="viewer-data">
																	<div className="data-single data-name">
																		<div className="single-content">
																			<span
																				className={`a`}
																				onClick={() =>
																					this.setState(
																						{
																							updateModalIsOpen: true,
																							selectedUser:
																								item.UserID,
																						}
																					)
																				}
																			>
																				{
																					userDisplayName
																				}
																			</span>
																		</div>
																	</div>
																	<div className="data-single data-users-trial">
																		<div
																			className="single-content"
																			data-label={`Trial users`}
																		>
																			{
																				item.trialUsers
																			}
																		</div>
																	</div>
																	<div className="data-single data-users-free">
																		<div
																			className="single-content"
																			data-label={`Free users`}
																		>
																			{
																				item.freeUsers
																			}
																		</div>
																	</div>
																	<div className="data-single data-users-premium">
																		<div
																			className="single-content"
																			data-label={`Premium users`}
																		>
																			{
																				item.premiumUsers
																			}
																		</div>
																	</div>
																	<div className="data-single data-users-enterprise">
																		<div
																			className="single-content"
																			data-label={`Enterprise users`}
																		>
																			{
																				item.enterpriseUsers
																			}
																		</div>
																	</div>
																	<div className="data-single data-users-business">
																		<div
																			className="single-content"
																			data-label={`Business users`}
																		>
																			{
																				item.businessUsers
																			}
																		</div>
																	</div>
																	<div className="data-single data-users-paid">
																		<div
																			className="single-content"
																			data-label={`Total paid`}
																		>
																			{
																				item.totalPaid
																			}
																		</div>
																	</div>
																</div>
															);
													  })
													: ""}

												{UpdateAgentModal(this)}
											</div>
										</div>

										{showPagination ? (
											<div className="section-pagination">
												<font>
													Showing{" "}
													{userPagination.data_start}{" "}
													to {userPagination.data_end}{" "}
													out of{" "}
													{userPagination.data_total}
												</font>
												<nav
													className="pagination-nav"
													aria-label="..."
												>
													<ReactPaginate
														previousLabel={
															"previous"
														}
														nextLabel={"next"}
														breakLabel={"..."}
														breakClassName={
															"break-me"
														}
														pageCount={
															userPagination.page_last
														}
														initialPage={
															userPagination.page -
															1
														}
														marginPagesDisplayed={2}
														pageRangeDisplayed={5}
														containerClassName={
															"pagination"
														}
														previousClassName={
															"page-item"
														}
														nextClassName={
															"page-item"
														}
														pageClassName={
															"page-item"
														}
														subContainerClassName={
															"pages pagination"
														}
														activeClassName={
															"active"
														}
														pageLinkClassName={
															"page-link"
														}
														previousLinkClassName={
															"page-link"
														}
														nextLinkClassName={
															"page-link"
														}
														onPageChange={
															this
																.handlePagination
														}
													/>
												</nav>
											</div>
										) : (
											""
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);

		function UpdateAgentModal(that) {
			let { selectedUser: userID } = that.state;
			return (
				<Modal
					isOpen={that.state.updateModalIsOpen}
					contentLabel="Example Modal"
				>
					<div className="basic-modal">
						<div className="modal-header">
							<h3>Affiliate details</h3>
							<button
								className="btn close-modal"
								type={`button`}
								onClick={() => {
									that.submitHandler();
									that.setState({
										updateModalIsOpen: false,
									});
								}}
							>
								<i className="icofont-close-line" />
							</button>
						</div>
						<div className="modal-content">
							<AffiliatePreview userID={userID} />
						</div>
					</div>
				</Modal>
			);
		}
	}

	openReOrderModal = (orderInfo) => {
		this.setState({
			ReOrderModalOpen: orderInfo,
		});
	};
	closeReOrderModal = () => {
		this.setState({
			ReOrderModalOpen: null,
		});
	};

	openNewTicketModal = (item) => {
		this.setState({
			NewTicketModalOpen: item.OrderID,
		});
	};

	closeNewTicketModal = () => {
		this.setState({
			NewTicketModalOpen: false,
		});
	};
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	affiliateData: state.affiliateData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	bodyClassController,
	retrieveAffiliates,
};
export default connect(mapStateToProps, mapDispatchToProps)(AffiliatesPage);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
