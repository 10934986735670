import React from "react";
import { connect } from "react-redux";
import {
	registerAffiliates,
	retrieveUsersTemp,
} from "../../store/actions/userActions";
import AsyncSelect from "react-select/async";

class NewAffiliateForm extends React.Component {
	state = {
		firstName: "",
		lastName: "",
		email: "",
		error: {},
		isLoading: false,
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	resetFormData = () => {
		this.setState({
			firstName: "",
			lastName: "",
			email: "",
		});
	};

	submitHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		let { firstName, lastName, email, userID } = this.state;

		this.props.registerAffiliates(
			{ userID },
			this.props.history,
			this.stopLoading,
			this.resetFormData
		);
	};

	componentDidMount() {
		let { preData } = this.props;

		// set default info if data provided
		if (preData) {
			this.setState({
				promoAuthor: preData.promoAuthor,
			});
		}
	}
	promiseOptions = async (inputValue) => {
		let allUsers = await this.props.retrieveUsersTemp({
			keyword: inputValue,
		});

		this.setState({
			allUsers: allUsers.data,
		});

		let options = [];

		for (const i of allUsers.data) {
			let userDisplayName = i.name
				? i.name
				: `${i.firstName} ${i.lastName}`;
			userDisplayName = `${userDisplayName} | ${i.email}`;

			options.push({ value: i.UserID, label: userDisplayName });
		}

		return options;
	};
	handleChange = (newValue, actionMeta) => {
		console.group("Value Changed");
		console.log(newValue.value);
		this.setState({
			userID: newValue.value,
		});

		// Set selected user
		let { allUsers } = this.state;
		for (const i of allUsers) {
			if (i.UserID === newValue.value) {
				this.setState({
					selectedUser: i,
				});
				console.log("selected user founded");
			}
		}

		console.log(`action: ${actionMeta.action}`);
		console.groupEnd();
	};
	render() {
		let { firstName, lastName, email, isLoading, error, selectedUser } =
			this.state;
		console.log(selectedUser);

		return (
			<form onSubmit={this.submitHandler} action="">
				<div className="row">
					<div className="col-md-12 p-4">
						<div className="single-section">
							{/*<div className="section-title">*/}
							{/*	<h4>Affiliate details</h4>*/}
							{/*</div>*/}
							<div className="form-group has-error">
								<AsyncSelect
									cacheOptions
									defaultOptions
									placeholder={`Search user...`}
									loadOptions={this.promiseOptions}
									onChange={this.handleChange}
								/>
							</div>

							<div className="row">
								<div className="col-md-6 preview-user-info">
									<table>
										<tr>
											<th>Name:</th>
											<td>
												{selectedUser
													? `${selectedUser.firstName} ${selectedUser.lastName}`
													: ".."}
											</td>
										</tr>
										<tr>
											<th>Address:</th>
											<td>..</td>
										</tr>
										<tr>
											<th>Address:</th>
											<td>..</td>
										</tr>
										<tr>
											<th>City:</th>
											<td>..</td>
										</tr>
										<tr>
											<th>State:</th>
											<td>..</td>
										</tr>
									</table>
								</div>

								<div className="col-md-6 preview-user-info">
									<table>
										<tr>
											<th>User ID:</th>
											<td>
												{selectedUser
													? selectedUser.UserID
													: ".."}
											</td>
										</tr>
										<tr>
											<th>Email:</th>
											<td>
												{selectedUser
													? selectedUser.email
													: ".."}
											</td>
										</tr>
									</table>
								</div>
							</div>

							<div className="form-actions text-right">
								<button
									type={isLoading ? "button" : "submit"}
									className="btn btn-primary is-invalid"
								>
									{" "}
									{isLoading ? (
										<i className="fas fa-spinner fa-spin"></i>
									) : (
										"Convert User to Affiliate "
									)}{" "}
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	registerAffiliates,
	retrieveUsersTemp,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewAffiliateForm);
