import * as Types from '../actions/types'

const init = {
	data: {},
	pagination: {},
	error: {},
	selected: {},
	success: '',
}

const promoReducer = (state = init, action) => {
	switch (action.type) {

		case Types.PROMO_RETRIEVE: {
			return {
				...state,
				data: action.payload.response.data,
				pagination: action.payload.response.pagination,
				selected: {},
				error: {},
			}
		}

		case Types.PROMO_NEW: {
			// if (Object.size(state.data) > 0) {
			if (Object.keys(state.data).length) {
				if (action.payload.promoInfo) {
					state.data.unshift(action.payload.promoInfo)
				}
			} else {
				state.data = action.payload.promoInfo
			}

			return {
				...state,
			}

		}

		default: return state
	}
}

export default promoReducer