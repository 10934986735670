import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import bodyClassController from "../../store/utils/bodyClassController";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import PageBreadcrumb from "../../components/default/pageBreadcrumb";

import setPageData from "../../store/actions/setPageData";
import BusinessSideMenu from "../../components/menu/BusinessSideMenu";
import Icofont from "react-icofont";
import Modal from "react-modal";
import ReactPaginate from "react-paginate";
import { retrieveProspectAction } from "../../store/actions/prospectActions";
import { ViewExactDate } from "../../utils/DateViewer";
import { CSVLink } from "react-csv";

class BusinessProspectsPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: {},
		};
	}

	setPageData() {
		this.props.setPageData({
			title: "Prospects",
			totalUsers: "...",
			pageID: "prospects",
		});
	}

	retrieveCompanyProspect = async (data) => {
		this.setState({
			dataLoading: true,
		});

		let { page, keyword } = this.state;
		await this.props.retrieveProspectAction({
			page,
			keyword,
		});

		this.props.setPageData({
			...this.props.pageData,
			totalUsers: this.props.prospectorData.pagination.data_total,
		});

		this.setState({
			dataLoading: false,
		});
	};

	componentDidMount() {
		this.setPageData();
		this.retrieveCompanyProspect();
	}

	componentWilMount() {}

	openModal() {
		this.setState({
			modalIsOpen: true,
		});
	}
	closeModal() {
		this.setState({
			modalIsOpen: false,
		});
	}

	render() {
		this.props.bodyClassController(this.props.pageData);
		let { pageData } = this.props;

		let { prospectorData } = this.props;

		let allProspectors, userPagination, showPagination;
		if (
			prospectorData &&
			prospectorData.data &&
			this.props.prospectorData.pagination.data_total > 0
		) {
			allProspectors = prospectorData.data.length
				? prospectorData.data
				: null;
			userPagination = prospectorData.pagination
				? prospectorData.pagination
				: null;
			showPagination = userPagination.page_last > 1;
		}
		return (
			<>
				<Helmet>
					{" "}
					<title>{pageData.title}</title>{" "}
				</Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<BusinessSideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{`All Prospect Information collected from all users (${pageData.totalUsers})`}</h1>
									</div>

									<span
										className="btn btn-primary ml-3"
										onClick={() => this.openModal()}
									>
										<span className="icon-wrapper">
											<Icofont icon="icofont-plus-circle" />
										</span>
										<span className="icon-text">
											{" Export Contacts"}
										</span>
									</span>

									{NewBizAccModal(this)}
								</div>

								<div className="page-content">
									<div className="table-area table-responsive">
										<div className="data-viewer data-business-prospects">
											<div className="viewer-header">
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Name
														</div>
														<div className="item-search">
															<input
																type="text"
																placeholder={`Search...`}
															/>
														</div>
													</div>

													<div className="item-action">
														<div className="action-search">
															<i className="fa icofont-search-2 " />
														</div>
														<div className="action-sort">
															<i className="fa fa-sort-amount-up" />
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Email
														</div>
														<div className="item-search">
															<input
																type="text"
																placeholder={`Search...`}
															/>
														</div>
													</div>

													<div className="item-action">
														<div className="action-search">
															<i className="fa icofont-search-2 " />
														</div>
														<div className="action-sort">
															<i className="fa fa-sort-amount-up" />
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Phone
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Date/time
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															User
														</div>
													</div>
												</div>
											</div>
											<div className="viewer-content">
												{allProspectors
													? allProspectors.map(
															(item) => {
																return (
																	<div className="viewer-data">
																		<div className="data-single data-name">
																			<div className="single-content">
																				{`${item.first_name} ${item.last_name}`}
																			</div>
																		</div>
																		<div className="data-single">
																			<div className="single-content">
																				{item
																					.adi_info
																					.emails
																					? item.adi_info.emails.map(
																							(
																								i
																							) => {
																								return (
																									<font>
																										{
																											i.value
																										}
																									</font>
																								);
																							}
																					  )
																					: ""}
																			</div>
																		</div>
																		<div className="data-single">
																			<div className="single-content">
																				{item
																					.adi_info
																					.phones
																					? item.adi_info.phones.map(
																							(
																								i
																							) => {
																								return (
																									<font>
																										{
																											i.value
																										}
																									</font>
																								);
																							}
																					  )
																					: ""}
																			</div>
																		</div>
																		<div className="data-single">
																			<div className="single-content">
																				<ViewExactDate
																					date={
																						item.date
																					}
																					format="MM-DD-YYYY HH-MM-A"
																				/>
																			</div>
																		</div>
																		<div className="data-single">
																			<div className="single-content">
																				{
																					item
																						.cards[0]
																						.cardName
																				}
																			</div>
																		</div>
																	</div>
																);
															}
													  )
													: ""}
											</div>
										</div>
										<div className="section-pagination">
											<font>
												Showing{" "}
												{userPagination?.data_start} to{" "}
												{userPagination?.data_end} out
												of {userPagination?.data_total}
											</font>
											{showPagination && (
												<nav
													className="pagination-nav"
													aria-label="..."
												>
													<ReactPaginate
														previousLabel={
															"previous"
														}
														nextLabel={"next"}
														breakLabel={"..."}
														breakClassName={
															"break-me"
														}
														pageCount={
															userPagination.page_last
														}
														initialPage={
															userPagination.page -
															1
														}
														marginPagesDisplayed={2}
														pageRangeDisplayed={5}
														containerClassName={
															"pagination"
														}
														previousClassName={
															"page-item"
														}
														nextClassName={
															"page-item"
														}
														pageClassName={
															"page-item"
														}
														subContainerClassName={
															"pages pagination"
														}
														activeClassName={
															"active"
														}
														pageLinkClassName={
															"page-link"
														}
														previousLinkClassName={
															"page-link"
														}
														nextLinkClassName={
															"page-link"
														}
														onPageChange={
															this
																.handlePagination
														}
													/>
												</nav>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);

		function NewBizAccModal(that) {
			let headers = [
				{ label: "Name", key: "first_name" },
				{ label: "Email", key: "email" },
				{ label: "Phone", key: "phone" },
			];

			let prospectorData = that.props.prospectorData.data;
			let dataAll = [
				{
					firstname: "Ahmed",
					lastname: "Tomi",
					email: "ah@smthing.co.com",
				},
				{
					firstname: "Raed",
					lastname: "Labes",
					email: "rl@smthing.co.com",
				},
				{
					firstname: "Yezzi",
					lastname: "Min l3b",
					email: "ymin@cocococo.com",
				},
			];

			return (
				<Modal
					isOpen={that.state.modalIsOpen}
					contentLabel="Example Modal"
				>
					<div className="basic-modal">
						<div className="modal-header">
							<h3>Export Contacts</h3>
							<button
								className="btn close-modal"
								type={`button`}
								onClick={() => {
									that.closeModal();
								}}
							>
								<i className="icofont-close-line" />
							</button>
						</div>
						<div className="modal-content">
							<CSVLink data={prospectorData} headers={headers}>
								Download Now!
							</CSVLink>
						</div>
					</div>
				</Modal>
			);
		}
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
	prospectorData: state.prospectorData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	retrieveProspectAction,
	bodyClassController,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BusinessProspectsPage);
