import React from "react";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";
import ReactPaginate from "react-paginate";

import bodyClassController from "../../store/utils/bodyClassController";

import setPageData from "../../store/actions/setPageData";
import Modal from "react-modal";
import UpdateBizCardForm from "../business-account/UpdateBizCardForm";
import { retrieveCardAction } from "../../store/actions/cardActions";
import SendCardSetupMailForm from "../business-account/SendCardSetupMailForm";

class ShowBusinessUsers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ReOrderModalOpen: null,
			expandedItem: null,
			NewTicketModalOpen: false,
			accountType: "",
			autoRenew: false,
			paymentStatus: "activeSubscription",
			advanceFilter: false,
			dataLoading: false,
			orderBy: "date",
			orderSort: "DESC",
			promoCode: "",
			keyword: "",
			error: {},
			page: 1,
		};
	}
	changeHandler = async (event) => {
		let stateName, stateValue;
		if (event.target.type == "checkbox") {
			stateName = event.target.name;
			stateValue = event.target.checked;
		} else {
			stateName = event.target.name;
			stateValue = event.target.value;
		}

		this.setState(
			{
				[stateName]: stateValue,
			},
			() => {
				console.log("type", event.target.type);
				if (
					event.target.type === "checkbox" ||
					event.target.type === "select-one"
				) {
					this.submitHandler();
				}
			}
		);
	};

	submitHandler = async (event) => {
		this.setState({
			dataLoading: true,
		});

		let { page, keyword } = this.state;
		await this.props.retrieveCardAction({
			page,
			keyword,
		});

		this.props.setPageData({
			...this.props.pageData,
			totalUsers: this.props.cardData.pagination.data_total,
		});

		this.setState({
			dataLoading: false,
		});
	};

	// set data sorting //
	setSorting = (orderBy, orderSort) => {
		this.setState({ orderBy, orderSort }, () => {
			this.submitHandler();
		});
	};

	componentDidMount() {}

	componentWilMount() {}

	handlePagination = (data) => {
		this.setState(
			{
				page: data.selected + 1,
			},
			() => {
				// this.props.retrieveUsers({ page: this.state.page })
				this.submitHandler();
			}
		);
	};

	openModal(cardID) {
		this.setState({
			modalIsOpen: cardID,
		});
	}
	closeModal() {
		this.setState({
			modalIsOpen: false,
		});
		this.submitHandler();
	}
	openMailModal(cardID) {
		this.setState({
			modalMailIsOpen: cardID,
		});
	}
	closeMailModal() {
		this.setState({
			modalMailIsOpen: false,
		});
	}

	render() {
		this.props.bodyClassController(this.props.pageData);

		let { cardData } = this.props;

		let allCards, userPagination, showPagination;
		if (
			cardData &&
			cardData.data &&
			this.props.cardData.pagination.data_total > 0
		) {
			allCards = cardData.data.length ? cardData.data : null;
			userPagination = cardData.pagination ? cardData.pagination : null;
			showPagination = userPagination.page_last > 1;
		}
		return (
			<div className="table-area table-responsive">
				<div className="data-viewer data-business-users">
					<div className="viewer-header">
						<div className="header-item">
							<div className="item-left">
								<div className="item-name">Employee Name</div>
								<div className="item-search">
									<input
										type="text"
										placeholder={`Search...`}
									/>
								</div>
							</div>

							<div className="item-action">
								<div className="action-search">
									<i className="fa icofont-search-2 " />
								</div>
								<div className="action-sort">
									<i className="fa fa-sort-amount-up" />
								</div>
							</div>
						</div>
						<div className="header-item">
							<div className="item-left">
								<div className="item-name">Email</div>
								<div className="item-search">
									<input
										type="text"
										placeholder={`Search...`}
									/>
								</div>
							</div>

							<div className="item-action">
								<div className="action-search">
									<i className="fa icofont-search-2 " />
								</div>
								<div className="action-sort">
									<i className="fa fa-sort-amount-up" />
								</div>
							</div>
						</div>
						<div className="header-item">
							<div className="item-left">
								<div className="item-name">Card URL</div>
							</div>
						</div>
						<div className="header-item">
							<div className="item-left">
								<div className="item-name">Template</div>
							</div>
						</div>
						<div className="header-item">
							<div className="item-left">
								<div className="item-name">Action</div>
							</div>
						</div>
					</div>
					<div className="viewer-content">
						{allCards
							? allCards.map((item) => {
									return (
										<div className="viewer-data">
											<div className="data-single data-name">
												<a
													className="single-content"
													onClick={() =>
														this.openModal(item.ID)
													}
												>
													{item.card_name}
												</a>
											</div>
											<div className="data-single">
												<div className="single-content">
													{item.card_email}
												</div>
											</div>
											<div className="data-single">
												<div className="single-content">
													<a
														href={item.card_link}
														target={`_blank`}
													>
														{item.card_link}
													</a>
												</div>
											</div>
											<div className="data-single">
												<div className="single-content">
													{item.templateTitle}
												</div>
											</div>
											<div className="data-single">
												<div className="single-content">
													<a
														className="single-content"
														onClick={() =>
															this.openMailModal(
																item
															)
														}
													>
														Send Setup Email
													</a>
												</div>
											</div>
										</div>
									);
							  })
							: ""}
					</div>
				</div>
				<div className="section-pagination">
					<font>
						Showing {userPagination?.data_start} to{" "}
						{userPagination?.data_end} out of{" "}
						{userPagination?.data_total}
					</font>
					{showPagination && (
						<nav className="pagination-nav" aria-label="...">
							<ReactPaginate
								previousLabel={"previous"}
								nextLabel={"next"}
								breakLabel={"..."}
								breakClassName={"break-me"}
								pageCount={userPagination.page_last}
								initialPage={userPagination.page - 1}
								marginPagesDisplayed={2}
								pageRangeDisplayed={5}
								containerClassName={"pagination"}
								previousClassName={"page-item"}
								nextClassName={"page-item"}
								pageClassName={"page-item"}
								subContainerClassName={"pages pagination"}
								activeClassName={"active"}
								pageLinkClassName={"page-link"}
								previousLinkClassName={"page-link"}
								nextLinkClassName={"page-link"}
								onPageChange={this.handlePagination}
							/>
						</nav>
					)}
				</div>
				{UpdateCardModal(this)}
				{SendLoginMailModal(this)} {/*Send mail modal*/}
			</div>
		);

		function UpdateCardModal(that) {
			let cardID = that.state.modalIsOpen;
			return (
				<Modal
					isOpen={that.state.modalIsOpen}
					contentLabel="Example Modal"
				>
					<div className="basic-modal">
						<div className="modal-header">
							<h3>Update card</h3>
							<button
								className="btn close-modal"
								type={`button`}
								onClick={() => {
									that.closeModal();
								}}
							>
								<i className="icofont-close-line" />
							</button>
						</div>
						<div className="modal-content">
							<UpdateBizCardForm cardID={cardID} />
						</div>
					</div>
				</Modal>
			);
		}

		function SendLoginMailModal(that) {
			let cardDetails = that.state.modalMailIsOpen;
			return (
				<Modal
					isOpen={that.state.modalMailIsOpen}
					contentLabel="Example Modal"
				>
					<div className="basic-modal">
						<div className="modal-header">
							<h3>Send User Email for Setup</h3>
							<button
								className="btn close-modal"
								type={`button`}
								onClick={() => {
									that.closeMailModal();
								}}
							>
								<i className="icofont-close-line" />
							</button>
						</div>
						<div className="modal-content">
							<SendCardSetupMailForm cardDetails={cardDetails} />
						</div>
					</div>
				</Modal>
			);
		}
	}

	openReOrderModal = (orderInfo) => {
		this.setState({
			ReOrderModalOpen: orderInfo,
		});
	};
	closeReOrderModal = () => {
		this.setState({
			ReOrderModalOpen: null,
		});
	};

	openNewTicketModal = (item) => {
		this.setState({
			NewTicketModalOpen: item.OrderID,
		});
	};

	closeNewTicketModal = () => {
		this.setState({
			NewTicketModalOpen: false,
		});
	};
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	cardData: state.cardData,
});
const mapDispatchToProps = {
	logout,
	bodyClassController,
	retrieveCardAction,
	setPageData,
};
export default connect(mapStateToProps, mapDispatchToProps)(ShowBusinessUsers);
