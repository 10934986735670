import React from "react";
import { connect } from "react-redux";
import {
	retrieveUserCards,
	updateUserAction,
} from "../../store/actions/userActions";
import QRCode from "qrcode.react";

class UserCards extends React.Component {
	state = {
		error: {},
		isLoading: false,
		title: "",
		rangeFrom: "",
		rangeTo: "",
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	async componentDidMount() {
		let { userID } = this.props;

		// Get user files
		let userCards = await this.props.retrieveUserCards({
			cardAuthor: userID,
		});

		// Set data to state
		this.setState({
			userID,
			userCards,
		});
	}

	render() {
		let { userCards } = this.state;

		return !userCards ? (
			<div>Loading...</div>
		) : (
			<div className={`row`}>
				<div className="user-cards">
					<table className="table">
						{userCards
							? userCards.map((item) => {
									return (
										<tr>
											<td>{item.card_name}</td>
											<td>{item.card_email}</td>
											<td>{item.card_phone}</td>
											<td>
												<a
													href={item.card_link}
													target="_blank"
												>
													{item.card_link}
												</a>
											</td>
										</tr>
									);
							  })
							: ""}
					</table>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	updateUserAction,
	retrieveUserCards,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserCards);
