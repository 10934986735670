import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../store/actions/authActions'
import ReactPaginate from 'react-paginate';
import Icofont from 'react-icofont';
import { ViewDate, ViewExactDate } from '../utils/DateViewer'


import bodyClassController from '../store/utils/bodyClassController'
import PrimaryMenu from '../components/menu/headerPrimaryMenu'
import SideMenu from '../components/menu/sideMenu'
import PageBreadcrumb from '../components/default/pageBreadcrumb'
import NewPromoModal from '../components/promoCode/NewPromoModal'

import setPageData from '../store/actions/setPageData'
import { retrieveUsers } from '../store/actions/userActions'

class HomePage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ReOrderModalOpen: null,
			expandedItem: null,
			NewTicketModalOpen: false,
			accountType: '',
			autoRenew: false,
			paymentStatus: 'activeSubscription',
			advanceFilter: false,
			dataLoading: false,
			orderBy: 'date',
			orderSort: 'DESC',
			promoCode: '',
			keyword: '',
			error: {},
			page: 1,
		}
	}
	changeHandler = async (event) => {
		let stateName, stateValue;
		if (event.target.type == 'checkbox') {
			stateName = event.target.name;
			stateValue = event.target.checked;
		} else {
			stateName = event.target.name;
			stateValue = event.target.value;
		}


		this.setState({
			[stateName]: stateValue
		}, () => {
			console.log('type', event.target.type);
			if (event.target.type === 'checkbox' || event.target.type === 'select-one') {
				this.submitHandler()
			}
		})
	}

	submitHandler = async (event) => {
		if (event != null) {
			event.preventDefault()
		}

		this.setState({
			dataLoading: true
		})

		let { page, promoCode, keyword, accountType, orderBy, orderSort, autoRenew, paymentStatus } = this.state
		await this.props.retrieveUsers({
			page,
			promoCode,
			keyword,
			accountType,
			orderBy,
			orderSort,
			paymentStatus,
			autoRenew
		})

		this.setState({
			dataLoading: false
		})
	}

	setPageData() {
		this.props.setPageData({
			title: 'Home',
			pageID: 'home_page'
		});
	}

	// set data sorting //
	setSorting = (orderBy, orderSort) => {
		this.setState({ orderBy, orderSort }, () => {
			this.submitHandler()
		})
	}

	componentDidMount() {
		this.setPageData()
		this.submitHandler()
	}

	componentWilMount() {
	}

	handlePagination = data => {
		this.setState({
			page: data.selected + 1,
		}, () => {
			// this.props.retrieveUsers({ page: this.state.page })
			this.submitHandler()
		});
	}

	render() {
		this.props.bodyClassController(this.props.pageData)

		let { userData, pageData } = this.props
		let { promoCode, keyword, expandedItem, orderBy, orderSort, autoRenew, advanceFilter, paymentStatus, dataLoading } = this.state
		let allUsers, userPagination, showPagination;
		if (userData && userData.data) {
			allUsers = userData.data.length ? userData.data : null
			userPagination = userData.pagination ? userData.pagination : null

			showPagination = userPagination.page_last > 1 ? true : false
		}
		return (
			<>
				<Helmet> <title>{pageData.title}</title> </Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<SideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{pageData.title}</h1>
									</div>
								</div>

								<div className="page-content">
									<h1>Coming soon...</h1>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}


	openReOrderModal = orderInfo => {
		this.setState({
			ReOrderModalOpen: orderInfo
		})
	}
	closeReOrderModal = () => {
		this.setState({
			ReOrderModalOpen: null
		})
	}



	openNewTicketModal = (item) => {
		this.setState({
			NewTicketModalOpen: item.OrderID
		})
	}

	closeNewTicketModal = () => {
		this.setState({
			NewTicketModalOpen: false
		})
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
})
const mapDispatchToProps = ({
	logout,
	setPageData,
	bodyClassController,
	retrieveUsers,
})
export default connect(mapStateToProps, mapDispatchToProps)(HomePage)