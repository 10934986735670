import axios from "axios";
import * as Types from "./types";

import { manageError } from "../utils/axiosResponseError";
import { showSuccess } from "../utils/configToast";

export const insertSubscriptionAction = (user) => async (dispatch) => {
	let responseData;
	await axios
		.post("subscription/admin/insert", user)
		.then((res) => {
			responseData = res.data;
			dispatch({
				type: Types.SUBSCRIPTION_SET,
				payload: {
					fileInfo: responseData.data,
					error: {},
				},
			});
			let msgSuccess = responseData.message
				? responseData.message
				: "Successfully added";
			showSuccess(msgSuccess);
			responseData = responseData ? responseData : true;
		})
		.catch((error) => {
			manageError(error);
		});
	return responseData;
};

export const updateSubscriptionAction = (user) => async (dispatch) => {
	let responseData;
	await axios
		.post("subscription/admin/update", user)
		.then((res) => {
			responseData = res.data;
			let msgSuccess = responseData.message
				? responseData.message
				: "Successfully updated";
			showSuccess(msgSuccess);
		})
		.catch((error) => {
			manageError(error);
		});

	return responseData;
};

export const retrieveSubscriptionAction = (postData) => async (dispatch) => {
	let responseData;
	await axios
		.post("subscription/list", postData)
		.then((res) => {
			responseData = res.data;
			dispatch({
				type: Types.SUBSCRIPTION_RETRIEVE,
				payload: {
					response: responseData,
					error: {},
				},
			});
		})
		.catch((error) => {
			manageError(error);
		});

	return responseData;
};

export const retrieveSubscriptionTempAction =
	(postData) => async (dispatch) => {
		let responseData;
		await axios
			.post("subscription/list", postData)
			.then((res) => {
				responseData = res.data;
			})
			.catch((error) => {
				manageError(error);
			});

		return responseData;
	};
export const retrieveSubscriptionTempAdminAction =
	(postData) => async (dispatch) => {
		let responseData;
		await axios
			.post("subscription/admin/list", postData)
			.then((res) => {
				responseData = res.data;
			})
			.catch((error) => {
				manageError(error);
			});

		return responseData;
	};
