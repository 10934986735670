import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import bodyClassController from "../../store/utils/bodyClassController";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import SideMenu from "../../components/menu/sideMenu";

import setPageData from "../../store/actions/setPageData";
import {
	retrieveReportDetails,
	updateReportDetails,
} from "../../store/actions/reportsActions";
import { ViewExactDate } from "../../utils/DateViewer";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import NewReportForm from "../../components/report/NewReportForm";

class ReportsPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			reportID: this.props.match.params.reportID,
			reportDetails: {},
			error: {},
			selectedAffiliate: {},
		};
	}
	changeHandler = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	submitHandler = async (event) => {
		if (event != null) {
			event.preventDefault();
		}

		let { reportID } = this.state;

		let reportDetails = await this.props.retrieveReportDetails({
			reportID,
		});
		this.setState({
			reportDetails,
		});
	};

	submitPaidHandler = async (event) => {
		if (event != null) {
			event.preventDefault();
		}

		let { selectedAffiliate, selectedPaymentDate } = this.state;

		this.setState({ isLoading: true });
		let reportDetails = await this.props.updateReportDetails({
			id: selectedAffiliate.id,
			columns: {
				paymentStatus: true,
				paymentDate: selectedPaymentDate,
			},
		});
		this.setState({
			reportDetails,
			markPaidModalIsOpen: null,
			isLoading: false,
		});
	};
	submitUploadStatementHandler = async (event) => {
		if (event != null) {
			event.preventDefault();
		}

		let {
			selectedAffiliate,
			selectedStatementMonth,
			selectedStatementYear,
		} = this.state;

		this.setState({ isLoading: true });
		let reportDetails = await this.props.updateReportDetails({
			id: selectedAffiliate.id,
			columns: {
				approvalStatus: true,
				statementMonth: selectedStatementMonth,
				statementYear: selectedStatementYear,
			},
		});
		this.setState({
			reportDetails,
			uploadStatementModalIsOpen: null,
			isLoading: false,
		});
	};

	setPageData() {
		this.props.setPageData({
			title: "Report details",
			pageID: "reports",
		});
	}

	componentDidMount() {
		this.setPageData();
		this.submitHandler();
	}

	componentWilMount() {}

	render() {
		this.props.bodyClassController(this.props.pageData);

		let { pageData } = this.props;

		let { report, affiliates } = this.state.reportDetails;

		return (
			<>
				<Helmet>
					{" "}
					<title>{pageData.title}</title>{" "}
				</Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<SideMenu />

						{report ? (
							<div className="col-md-10 affiliate-detailed-report">
								<div className="report-header">
									<div className="action-left">
										<div className="action-single">
											<i className="icofont-printer"></i>
											<font>PRINTER</font>
										</div>
										<div className="action-single">
											<i className="icofont-printer"></i>
											<font>PRINTER</font>
										</div>
									</div>
									<div className="title-section">
										<h3>STATEMENTS</h3>
										<p>
											{`Date`}{" "}
											<ViewExactDate
												date={report.rangeFrom}
												format="MM-DD-YYYY"
											/>
											{` - `}
											<ViewExactDate
												date={report.rangeTo}
												format="MM-DD-YYYY"
											/>
										</p>
									</div>
									<div className="action-right">
										<Link
											to="/reports"
											className="nav-link"
										>
											<i className="icofont-close-line"></i>
										</Link>
									</div>
								</div>

								<div className="report-section">
									<div className="section-head">
										COMMISSION REPORT
										<div className="right">
											Date{" "}
											<ViewExactDate
												date={report.rangeFrom}
												format="MM-DD-YYYY"
											/>
											{` - `}
											<ViewExactDate
												date={report.rangeTo}
												format="MM-DD-YYYY"
											/>
										</div>
									</div>

									<div className="section-content">
										<div className="report-summery">
											{affiliates
												? affiliates.map((item) => {
														let disaplayName =
															item.name
																? item.name
																: `${item.firstName} ${item.lastName}`;
														let totalCommission =
															parseFloat(
																item.totalCommission
															);
														totalCommission =
															totalCommission > 0
																? "$" +
																  totalCommission
																: 0;

														return (
															<div className="summery-single">
																<div className="summery-name">
																	<a
																		href={`#${item.id}`}
																	>
																		{
																			disaplayName
																		}
																	</a>
																</div>
																<div className="summery-value">
																	{
																		totalCommission
																	}
																</div>
															</div>
														);
												  })
												: ""}
										</div>
									</div>
								</div>
								{affiliates
									? affiliates.map((item) => {
											let disaplayName = item.name
												? item.name
												: `${item.firstName} ${item.lastName}`;

											let totalCommission = parseFloat(
												item.totalCommission
											);
											totalCommission =
												totalCommission > 0
													? "$" + totalCommission
													: 0;
											// getting business account commission
											let commissionBusiness = parseFloat(
												item.commissionBusiness
											);
											commissionBusiness =
												commissionBusiness > 0
													? "$" + commissionBusiness
													: 0;

											let premiumCommission =
												item.premiumUsers > 0
													? "$" +
													  item.premiumUsers * 5
													: 0;
											return (
												<div
													className="report-section"
													id={item.id}
												>
													<div className="section-head">
														<div className="double-title">
															<div className="left">
																{disaplayName}
															</div>
															<div className="right">
																{`Statement Date `}
																<ViewExactDate
																	date={
																		report.rangeFrom
																	}
																	format="MM-DD-YYYY"
																/>
																{` - `}
																<ViewExactDate
																	date={
																		report.rangeTo
																	}
																	format="MM-DD-YYYY"
																/>
															</div>
														</div>
													</div>
													<div className="single-details">
														<div className="commission-summery">
															<table className="table table-bordered">
																<tr>
																	<th>
																		users
																	</th>
																	<th>
																		type
																	</th>
																	<th>
																		per user
																	</th>
																	<th>
																		total
																	</th>
																</tr>
																{/*Free users*/}
																<tr>
																	<td>
																		{
																			item.freeUsers
																		}
																	</td>
																	<td>
																		Free
																	</td>
																	<td>0</td>
																	<td>0</td>
																</tr>
																{/*Premium users*/}
																<tr>
																	<td>
																		{
																			item.trialUsers
																		}
																	</td>
																	<td>
																		Trial
																	</td>
																	<td>0</td>
																	<td>0</td>
																</tr>
																{/*noPlanTaken users*/}
																<tr>
																	<td>
																		{
																			item.noPlanTakenUsers
																		}
																	</td>
																	<td>
																		Inactive
																	</td>
																	<td>0</td>
																	<td>0</td>
																</tr>
																{/*Enterprise users*/}
																<tr>
																	<td>
																		{
																			item.premiumUsers
																		}
																	</td>
																	<td>
																		Premium
																	</td>
																	<td>$5</td>
																	<td>
																		{
																			premiumCommission
																		}
																	</td>
																</tr>
																{/*enterprise users*/}
																<tr>
																	<td>
																		{
																			item.enterpriseUsers
																		}
																	</td>
																	<td>
																		Enterprise
																	</td>
																	<td>0</td>
																	<td>0</td>
																</tr>
																{/*business users*/}
																<tr>
																	<td>
																		{
																			item.businessUsers
																		}
																	</td>
																	<td>
																		Business
																	</td>
																	<td>--</td>
																	<td>
																		{
																			commissionBusiness
																		}
																	</td>
																</tr>
															</table>
														</div>
														<div className="commission-action">
															<div
																className="action-single"
																onClick={() => {
																	this.setState(
																		{
																			selectedAffiliate:
																				item,
																			uploadStatementModalIsOpen: true,
																		}
																	);
																}}
															>
																<i className="big icofont-file-pdf"></i>
																<p>
																	UPLOAD
																	STATEMENT
																</p>
																{item.approvalStatus ===
																"1" ? (
																	<i className="marked-as-done icofont-check"></i>
																) : (
																	""
																)}
															</div>
															<div
																className="action-single"
																onClick={() => {
																	this.setState(
																		{
																			selectedAffiliate:
																				item,
																			markPaidModalIsOpen: true,
																		}
																	);
																}}
															>
																<i className="big icofont-check-circled"></i>
																<p>MARK PAID</p>
																{item.paymentStatus ===
																"1" ? (
																	<i className="marked-as-done icofont-check"></i>
																) : (
																	""
																)}
															</div>
															<div className="action-summery">
																{`${totalCommission} Total
																	Commissions`}
															</div>
														</div>
													</div>
												</div>
											);
									  })
									: ""}

								{/*Modal sections*/}
								{UploadStatementModal(this)}
								{MarkPaidModal(this)}
							</div>
						) : (
							<div>
								<h1 className="text-center">Loading...</h1>
							</div>
						)}
					</div>
				</div>
			</>
		);

		function UploadStatementModal(that) {
			let { isLoading } = that.state;
			return (
				<Modal
					isOpen={that.state.uploadStatementModalIsOpen}
					contentLabel="Example Modal"
				>
					<div className="basic-modal">
						<div className="modal-header">
							<h3>Upload statement</h3>
							<button
								className="btn close-modal"
								type={`button`}
								onClick={() => {
									that.setState({
										uploadStatementModalIsOpen: null,
									});
								}}
							>
								<i className="icofont-close-line"></i>
							</button>
						</div>
						<div className="modal-content">
							<form onSubmit={that.submitUploadStatementHandler}>
								<div className="form-group has-error">
									<label htmlFor="selectedStatementMonth">
										Statement month
									</label>
									<select
										name="selectedStatementMonth"
										id="selectedStatementMonth"
										className={`form-control`}
										onChange={that.changeHandler}
									>
										<option value="">--Select--</option>
										<option value="1">January</option>
										<option value="2">February</option>
										<option value="3">March</option>
										<option value="4">April</option>
										<option value="5">May</option>
										<option value="6">June</option>
										<option value="7">July</option>
										<option value="8">August</option>
										<option value="8">September</option>
										<option value="10">October</option>
										<option value="11">November</option>
										<option value="12">December</option>
									</select>
								</div>
								<div className="form-group has-error">
									<label htmlFor="selectedStatementYear">
										Statement year
									</label>
									<input
										type="number"
										className={`form-control`}
										name="selectedStatementYear"
										id="selectedStatementYear"
										min={new Date().getFullYear()}
										value={that.state.selectedStatementYear}
										onChange={that.changeHandler}
										required={true}
									/>
								</div>

								<div className="form-actions text-right">
									<button
										type={isLoading ? "button" : "submit"}
										className="btn btn-primary is-invalid"
									>
										{" "}
										{isLoading ? (
											<i className="fas fa-spinner fa-spin"></i>
										) : (
											"Send to agent portal"
										)}{" "}
									</button>
								</div>
							</form>
						</div>
					</div>
				</Modal>
			);
		}

		function MarkPaidModal(that) {
			let { isLoading } = that.state;
			return (
				<Modal
					isOpen={that.state.markPaidModalIsOpen}
					contentLabel="Example Modal a"
				>
					<div className="basic-modal">
						<div className="modal-header">
							<h3>Mark Paid</h3>
							<button
								className="btn close-modal"
								type={`button`}
								onClick={() => {
									that.setState({
										markPaidModalIsOpen: null,
									});
								}}
							>
								<i className="icofont-close-line"></i>
							</button>
						</div>
						<div className="modal-content">
							<form onSubmit={that.submitPaidHandler}>
								<div className="form-group has-error">
									<label htmlFor="selectedPaymentDate">
										Payment date
									</label>
									<input
										type="date"
										className={`form-control`}
										name="selectedPaymentDate"
										id="selectedPaymentDate"
										value={that.state.selectedPaymentDate}
										onChange={that.changeHandler}
										required={true}
									/>
								</div>

								<div className="form-actions text-right">
									<button
										type={isLoading ? "button" : "submit"}
										className="btn btn-primary is-invalid"
									>
										{" "}
										{isLoading ? (
											<i className="fas fa-spinner fa-spin"></i>
										) : (
											"Send to agent portal"
										)}{" "}
									</button>
								</div>
							</form>
						</div>
					</div>
				</Modal>
			);
		}
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	reportData: state.reportData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	bodyClassController,
	retrieveReportDetails,
	updateReportDetails,
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
