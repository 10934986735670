import * as Types from "../actions/types";

const init = {
	data: {},
	pagination: {},
	error: {},
};

const fileReducer = (state = init, action) => {
	switch (action.type) {
		case Types.FILES_RETRIEVE: {
			return {
				...state,
				data: action.payload.response.data,
				pagination: action.payload.response.pagination,
			};
		}

		case Types.FILES_SET: {
			if (Object.keys(state.data).length) {
				if (action.payload.fileInfo) {
					state.data.unshift(action.payload.fileInfo);
				}
			} else {
				state.data = action.payload.fileInfo;
			}

			return {
				...state,
			};
		}
		default:
			return state;
	}
};

export default fileReducer;
