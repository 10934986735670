import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import bodyClassController from "../../store/utils/bodyClassController";
import PageBreadcrumb from "../../components/default/pageBreadcrumb";

import setPageData from "../../store/actions/setPageData";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import Modal from "react-modal";
import {
	deletePromoCodes,
	insertPromoCodes,
	retrievePromoCodes,
} from "../../store/actions/promoCodesActions";
import { ViewExactDate } from "../../utils/DateViewer";
import { retrieveAgentReports } from "../../store/actions/reportsActions";
import "react-confirm-alert/src/react-confirm-alert.css";
import ViewReportUsers from "../../components/affiliate/ViewReportUsers";
import BusinessSideMenu from "../../components/menu/BusinessSideMenu";
import { retrieveTemplateAction } from "../../store/actions/templateActions"; // Import css

class BusinessTemplatePage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: {},
			modalIsOpen: false,
		};
	}

	openModal() {
		this.setState({
			modalIsOpen: true,
		});
	}

	closeModal() {
		this.setState({
			modalIsOpen: false,
		});
	}

	setPageData() {
		this.props.setPageData({
			title: "Templates",
			pageID: "templates",
			totalUsers: "...",
		});
	}

	componentDidMount() {
		this.setPageData();
		this.retrieveTemplates();
	}

	retrieveTemplates = async (data) => {
		await this.props.retrieveTemplateAction({
			cardDetails: true,
		});
	};

	componentWilMount() {}

	render() {
		this.props.bodyClassController(this.props.pageData);
		let promoCodes;
		let { pageData } = this.props;
		let { promoData } = this.props;
		let { viewUsers } = this.state;

		let { auth } = this.props;
		let { user } = auth;

		if (
			promoData &&
			promoData.data &&
			promoData.pagination.data_total > 0
		) {
			promoCodes = promoData.data;
		}

		let { reportData } = this.props;
		let allReports, userPagination, showPagination;
		if (reportData && reportData.data) {
			allReports = reportData.data.length ? reportData.data : null;
			userPagination = reportData.pagination
				? reportData.pagination
				: null;

			showPagination = userPagination.page_last > 1 ? true : false;
		}

		let allTemplates;
		let { templateData } = this.props;
		if (
			templateData &&
			templateData.data &&
			templateData.pagination.data_total > 0
		) {
			allTemplates = templateData.data.length ? templateData.data : null;
		}

		return (
			<>
				<Helmet>
					{" "}
					<title>{pageData.title}</title>{" "}
				</Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<BusinessSideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{pageData.title}</h1>
									</div>
								</div>

								<div className="page-content bg-transparent p-4">
									<div className="row templates">
										{allTemplates ? (
											allTemplates.map((item) => {
												let { cardDetails } = item;
												if (cardDetails) {
													return (
														<div className="col-md-4 template-single">
															<div className="template-title">
																{item.title}
															</div>
															<div className="template-content">
																<div className="card-cover">
																	<img
																		className={`img-fluid`}
																		src={
																			cardDetails.card_cover
																		}
																		alt={
																			cardDetails.card_name
																		}
																	/>
																</div>
																<div className="card-basic">
																	<div className="card-business">
																		{
																			cardDetails.card_name
																		}
																	</div>
																	<div className="card-name">
																		{
																			cardDetails.card_title
																		}
																	</div>
																	<div className="card-title">
																		{
																			cardDetails.card_address
																		}
																	</div>
																</div>
																<div className="card-tabs">
																	<div className="tab-single">
																		<a
																			href={
																				cardDetails.card_link
																			}
																			target={
																				"_blank"
																			}
																		>
																			<div className="tab-title">
																				Live
																				preview..
																			</div>
																		</a>
																	</div>
																</div>
															</div>
														</div>
													);
												} else {
													return (
														<div className="col-md-4 template-single">
															Loading card data...
														</div>
													);
												}
											})
										) : (
											<div>Nothing to show</div>
										)}

										<div className="col-md-12 text-center p-4">
											<p>
												Need updated to templates, Email
												support@nextcard.me with
												changes.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/*Show all modals*/}
				{ViewReportModal(this)}
			</>
		);

		function ViewReportModal(that) {
			let { isLoading, selectedReport: item } = that.state;

			let totalCommission = 0;
			let premiumCommission;
			if (item) {
				totalCommission = parseFloat(item.totalCommission);
				premiumCommission =
					item.premiumUsers > 0 ? "$" + item.premiumUsers * 5 : 0;
			}
			return (
				<Modal
					isOpen={that.state.reportDetailsModalIsOpen}
					contentLabel="Example Modal a"
				>
					{item ? (
						<div className="basic-modal">
							<div className="modal-header">
								<h3>Report details</h3>
								<button
									className="btn close-modal"
									type={`button`}
									onClick={() => {
										that.setState({
											reportDetailsModalIsOpen: null,
										});
									}}
								>
									<i className="icofont-close-line" />
								</button>
							</div>
							<div className="modal-content">
								<div className="agent-affiliate-detailed-report">
									<div className="report-section">
										<div className="section-head">
											<div className="double-title">
												<div className="left">
													{item.reportTitle}
												</div>
												<div className="right">
													{`Statement Date `}
													<ViewExactDate
														date={item.rangeFrom}
														format="MM-DD-YYYY"
													/>
													{` - `}
													<ViewExactDate
														date={item.rangeTo}
														format="MM-DD-YYYY"
													/>
												</div>
											</div>
										</div>
										<div className="single-details">
											<div className="commission-summery">
												<table className="table table-bordered">
													<tr>
														<th>users</th>
														<th>type</th>
														<th>per user</th>
														<th>total</th>
													</tr>
													{/*Free users*/}
													<tr>
														<td
															className={`cursor-pointer`}
															onClick={() => {
																that.setState({
																	viewUsers: {
																		type: "free",
																		snapshotID:
																			item.snapshotID,
																	},
																});
															}}
														>
															{item.freeUsers}
														</td>
														<td>Free</td>
														<td>0</td>
														<td>0</td>
													</tr>
													{/*Premium users*/}
													<tr>
														<td
															className={`cursor-pointer`}
															onClick={() => {
																that.setState({
																	viewUsers: {
																		type: "trial",
																		snapshotID:
																			item.snapshotID,
																	},
																});
															}}
														>
															{item.trialUsers}
														</td>
														<td>Trial</td>
														<td>0</td>
														<td>0</td>
													</tr>
													{/*noPlanTaken users*/}
													<tr>
														<td
															className={`cursor-pointer`}
															onClick={() => {
																that.setState({
																	viewUsers: {
																		type: "noPlanTaken",
																		snapshotID:
																			item.snapshotID,
																	},
																});
															}}
														>
															{
																item.noPlanTakenUsers
															}
														</td>
														<td>Inactive</td>
														<td>0</td>
														<td>0</td>
													</tr>
													{/*Enterprise users*/}
													<tr>
														<td
															className={`cursor-pointer`}
															onClick={() => {
																that.setState({
																	viewUsers: {
																		type: "premium",
																		snapshotID:
																			item.snapshotID,
																	},
																});
															}}
														>
															{item.premiumUsers}
														</td>
														<td>Premium</td>
														<td>$5</td>
														<td>
															{premiumCommission}
														</td>
													</tr>
													{/*enterprise users*/}
													<tr>
														<td
															className={`cursor-pointer`}
															onClick={() => {
																that.setState({
																	viewUsers: {
																		type: "enterprise",
																		snapshotID:
																			item.snapshotID,
																	},
																});
															}}
														>
															{
																item.enterpriseUsers
															}
														</td>
														<td>Enterprise</td>
														<td>0</td>
														<td>0</td>
													</tr>
													{/*business users*/}
													<tr>
														<td
															className={`cursor-pointer`}
															onClick={() => {
																that.setState({
																	viewUsers: {
																		type: "business",
																		snapshotID:
																			item.snapshotID,
																	},
																});
															}}
														>
															{item.businessUsers}
														</td>
														<td>Business</td>
														<td>0</td>
														<td>0</td>
													</tr>
												</table>
											</div>
											<div className="commission-action">
												<div className="action-single">
													{item.paymentStatus ===
													"1" ? (
														<>
															<i className="big icofont-check-circled text-success"></i>
															<font>PAID ON</font>
															<ViewExactDate
																date={
																	item.paymentDate
																}
																format="MM-DD-YYYY"
															/>
														</>
													) : (
														<>
															<i className="big icofont-check-circled"></i>
															<p>Pending</p>
														</>
													)}
												</div>
												<div className="action-summery">
													{`$${totalCommission} Total
																	Commissions`}
												</div>
											</div>
										</div>

										{viewUsers ? (
											<div className="show-users">
												<ViewReportUsers
													viewUsers={viewUsers}
												/>
											</div>
										) : (
											""
										)}
									</div>
								</div>
							</div>
						</div>
					) : (
						""
					)}
				</Modal>
			);
		}

		function SingleTemplate() {
			return (
				<div className="col-md-4 template-single">
					<div className="template-title">SALES TEMPLATE 1</div>
					<div className="template-content">
						<div className="card-cover">
							<img
								className={`img-fluid`}
								src="https://firebasestorage.googleapis.com/v0/b/nextcard-premium.appspot.com/o/cover%2Fimage_cropper_D0EEDFD2-AAB0-4537-AC42-A0C4D8FB82CF-3736-00000689120BFE57.jpg?alt=media&token=848f89ba-a11e-4463-91e4-cf546c83de5f"
								alt=""
							/>
						</div>
						<div className="card-basic">
							<div className="card-business">Business Name</div>
							<div className="card-name">Employee Name</div>
							<div className="card-title">Job Description</div>
						</div>
						<div className="card-tabs">
							<div className="tab-single">
								<div className="tab-title">About Us</div>
							</div>
							<div className="tab-single">
								<div className="tab-title">Products</div>
							</div>
							<div className="tab-single">
								<div className="tab-title">Testimonials</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
	promoData: state.promoData,
	reportData: state.reportData,
	templateData: state.templateData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	bodyClassController,
	insertPromoCodes,
	retrievePromoCodes,
	retrieveAgentReports,
	deletePromoCodes,
	retrieveTemplateAction,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BusinessTemplatePage);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
