import React from "react";
import { connect } from "react-redux";
import {
	insertSubscriptionAction,
	retrieveSubscriptionTempAdminAction,
	updateSubscriptionAction,
} from "../../store/actions/subscriptionActions";
import moment from "moment";

class BizSubscriptions extends React.Component {
	state = {
		error: {},
		isLoading: false,
		title: "",
		rangeFrom: "",
		rangeTo: "",
		affiliateCommissionType: "percentage",
		developerCommissionType: "percentage",
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	resetFormData = () => {
		this.setState({});
	};

	updateSubscriptionHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		let postData = {
			subscriptionID: this.state.subscriptionID,
			monthlyCharge: this.state.monthlyCharge,
			cardLimit: this.state.cardLimit,
			expireDate: this.state.expireDate,
			affiliateCommission: this.state.affiliateCommission,
			affiliateCommissionType: this.state.affiliateCommissionType,
			developerCommission: this.state.developerCommission,
		};
		let insertBusiness = await this.props.updateSubscriptionAction(
			postData
		);

		this.stopLoading();
		if (insertBusiness === true) {
			this.resetFormData();
		}
	};

	submitSubscriptionHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		let {
			monthlyCharge,
			cardLimit,
			affiliateCommission,
			affiliateCommissionType,
			developerCommission,
			developerCommissionType,
			userID,
			expireDate,
		} = this.state;

		let postData = {
			authorID: userID,
			expireDate,
			productID: 4,
			monthlyCharge,
			cardLimit,
			affiliateCommission,
			affiliateCommissionType,
			developerCommission,
			developerCommissionType,
			category: "business",
		};
		let insertBusiness = await this.props.insertSubscriptionAction(
			postData
		);

		this.stopLoading();
		if (insertBusiness) {
			this.resetFormData();
			this.getExistsSubscriptions();
		}
	};

	async componentDidMount() {
		this.getExistsSubscriptions();
	}

	async getExistsSubscriptions() {
		let { userID } = this.props;

		// set default info if data provided
		if (userID) {
			// Get user details
			let subscriptionDetails =
				await this.props.retrieveSubscriptionTempAdminAction({
					userID: userID,
					skipExpire: true,
					category: "business",
				});

			if (subscriptionDetails) {
				try {
					subscriptionDetails = subscriptionDetails.data[0];
				} catch (e) {
					subscriptionDetails = null;
				}

				if (subscriptionDetails) {
					let expireDate = moment(
						subscriptionDetails.expireDate
					).format("YYYY-MM-DD");

					console.log(expireDate);
					this.setState({
						subscriptionID: subscriptionDetails.ID,
						monthlyCharge: subscriptionDetails.monthlyCharge,
						cardLimit: subscriptionDetails.cardLimit,
						expireDate: expireDate,
						// expireDate: "2013-01-08",
						affiliateCommission:
							subscriptionDetails.affiliateCommission,
						affiliateCommissionType:
							subscriptionDetails.affiliateCommissionType,
						developerCommission: subscriptionDetails.devCommission,
						developerCommissionType:
							subscriptionDetails.developerCommissionType,
					});
				}
			}

			//
			this.setState({
				userID,
				subsCheckDone: true,
				subscriptionDetails,
			});
		}
	}

	render() {
		let { isLoading, error, subscriptionDetails, subsCheckDone } =
			this.state;

		return !subsCheckDone ? (
			<div>Loading...</div>
		) : (
			<form
				onSubmit={
					subscriptionDetails
						? this.updateSubscriptionHandler
						: this.submitSubscriptionHandler
				}
				action=""
			>
				<div className="row insert-new">
					<div className="col-md-12">
						<div className="single-section">
							<div className="section-title">
								<h4>Pricing & commissions</h4>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="monthlyCharge">
											Monthly charge
										</label>
									</div>
									<div className="col-md-8">
										<input
											type="number"
											className={
												error.monthlyCharge
													? "form-control is-invalid"
													: "form-control"
											}
											name="monthlyCharge"
											id="monthlyCharge"
											value={this.state.monthlyCharge}
											onChange={this.changeHandler}
											required={true}
											placeholder={``}
										/>
										{error.monthlyCharge && (
											<div className="invalid-feedback">
												{error.monthlyCharge}
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="cardLimit">
											Card limit
										</label>
									</div>
									<div className="col-md-8">
										<input
											type="number"
											className={
												error.cardLimit
													? "form-control is-invalid"
													: "form-control"
											}
											name="cardLimit"
											id="cardLimit"
											value={this.state.cardLimit}
											onChange={this.changeHandler}
											required={true}
											placeholder={``}
										/>
										{error.cardLimit && (
											<div className="invalid-feedback">
												{error.cardLimit}
											</div>
										)}
									</div>
								</div>
							</div>

							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="lastName">
											Expire date
										</label>
									</div>
									<div className="col-md-8">
										<input
											type="date"
											className={
												error.expireDate
													? "form-control is-invalid"
													: "form-control"
											}
											name="expireDate"
											id="expireDate"
											value={this.state.expireDate}
											onChange={this.changeHandler}
											required={true}
											placeholder={``}
										/>
										{error.expireDate && (
											<div className="invalid-feedback">
												{error.expireDate}
											</div>
										)}
									</div>
								</div>
							</div>
							<hr />

							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="phone">
											Agent commissions
										</label>
									</div>
									<div className="col-md-8">
										<div className="row">
											<div className="col-md-7">
												<input
													type="number"
													step="0.01"
													min="1"
													className={
														error.affiliateCommission
															? "form-control is-invalid"
															: "form-control"
													}
													name="affiliateCommission"
													id="affiliateCommission"
													value={
														this.state
															.affiliateCommission
													}
													onChange={
														this.changeHandler
													}
													required={true}
													placeholder={``}
												/>
												{error.affiliateCommission && (
													<div className="invalid-feedback">
														{
															error.affiliateCommission
														}
													</div>
												)}
											</div>

											{!subscriptionDetails ? (
												<div className="col-md-5">
													<select
														name="affiliateCommissionType"
														id="affiliateCommissionType"
														className={`form-control`}
														value={
															this.state
																.affiliateCommissionType
														}
														onChange={
															this.changeHandler
														}
													>
														<option value="percentage">
															Percentage
														</option>
														<option value="flat">
															Flat
														</option>
													</select>
												</div>
											) : (
												""
											)}
										</div>
									</div>
								</div>
							</div>
							<div className="form-group has-error">
								<div className="row">
									<div className="col-md-4">
										<label htmlFor="email">
											Developer commissions
										</label>
									</div>
									<div className="col-md-8">
										<div className="row">
											<div className="col-md-7">
												<input
													type="number"
													step="0.01"
													className={
														error.developerCommission
															? "form-control is-invalid"
															: "form-control"
													}
													name="developerCommission"
													id="developerCommission"
													value={
														this.state
															.developerCommission
													}
													min={"7.77"}
													onChange={
														this.changeHandler
													}
													required={true}
													placeholder={``}
												/>
												{error.developerCommission && (
													<div className="invalid-feedback">
														{
															error.developerCommission
														}
													</div>
												)}
											</div>
											{!subscriptionDetails ? (
												<div className="col-md-5">
													<select
														name="developerCommissionType"
														id="developerCommissionType"
														className={`form-control`}
														value={
															this.state
																.developerCommissionType
														}
														disabled
													>
														<option value="percentage">
															Percentage
														</option>
														<option value="flat">
															Flat
														</option>
													</select>
												</div>
											) : (
												""
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12">
						<div className="single-section">
							<div className="form-actions update">
								<button
									type={isLoading ? "button" : "submit"}
									className="btn btn-primary is-invalid"
								>
									{" "}
									{isLoading ? (
										<i className="fas fa-spinner fa-spin" />
									) : (
										"Update subscription"
									)}{" "}
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	insertSubscriptionAction,
	updateSubscriptionAction,
	retrieveSubscriptionTempAdminAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(BizSubscriptions);
