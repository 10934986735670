import React from "react";
import { connect } from "react-redux";
import AsyncSelect from "react-select/async";
import { sendNotification, retrieveUsersTemp } from '../../../store/actions/userActions';

class NewNotificationForm extends React.Component {
	state = {
		firstName: "",
		lastName: "",
		email: "",
		error: {},
		isLoading: false,
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	resetFormData = () => {
		this.setState({
			firstName: "",
			lastName: "",
			email: "",
		});
	};

	submitHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		let { notificationType, selectedUser, notificationTitle, notificationText } = this.state;
		let postData = {
			notificationType,
			selectedUser: selectedUser?.UserID,
			notificationTitle,
			notificationText
		};

		await this.props.sendNotification( postData );

		this.setState({ isLoading: false });
	};

	componentDidMount() {
		let { preData } = this.props;

		// set default info if data provided
		if (preData) {
			this.setState({
				promoAuthor: preData.promoAuthor,
			});
		}
	}
	notificationTypes = async (inputValue) => {
		let options = [];
		options.push({ value: "individual", label: "Individual" });
		options.push({ value: "freeUsers", label: "Free users" });
		options.push({ value: "trialUsers", label: "Trial users" });
		options.push({ value: "paidUsers", label: "Paid users" });
		options.push({ value: "cancelledUsers", label: "Cancel users" });
		return options;
	};

	promiseOptions = async (inputValue) => {
		let allUsers = await this.props.retrieveUsersTemp({
			keyword: inputValue,
		});

		this.setState({
			allUsers: allUsers.data,
		});

		let options = [];

		for (const i of allUsers.data) {
			let userDisplayName = i.name
				? i.name
				: `${i.firstName} ${i.lastName}`;
			userDisplayName = `${userDisplayName} | ${i.email}`;

			options.push({ value: i.UserID, label: userDisplayName });
		}

		return options;
	};
	handleChange = (newValue, actionMeta) => {
		console.group("Value Changed");
		console.log(newValue.value);
		this.setState({
			userID: newValue.value,
		});

		// Set selected user
		let { allUsers } = this.state;
		for (const i of allUsers) {
			if (i.UserID === newValue.value) {
				this.setState({
					selectedUser: i,
				});
				console.log("selected user founded");
			}
		}

		console.log(`action: ${actionMeta.action}`);
		console.groupEnd();
	};
	setNotificationType = (newValue, actionMeta) => {
		this.setState({
			notificationType: newValue.value,
		});
	};

	render() {
		let { firstName, lastName, email, isLoading, error, selectedUser, notificationType } =
			this.state;

		return (
			<form onSubmit={this.submitHandler} action="">
				<div className="row">
					<div className="col-md-12 p-4">
						<div className="single-section">
							{/*<div className="section-title">*/}
							{/*	<h4>Affiliate details</h4>*/}
							{/*</div>*/}
							<div className="form-group has-error">
								<AsyncSelect
									cacheOptions
									defaultOptions
									placeholder={`Notification type...`}
									loadOptions={this.notificationTypes}
									onChange={this.setNotificationType}
								/>
							</div>

							{(()=>{
								if (notificationType === 'individual'){
									return (<div className="form-group has-error">
										<AsyncSelect
											cacheOptions
											defaultOptions
											placeholder={`Search user...`}
											loadOptions={this.promiseOptions}
											onChange={this.handleChange}
										/>
									</div>)
								}
							})()}

							{(()=>{
								if (notificationType != null){
									return(
										<>
											<div className="single-section">
												<div className="section-title">
													<h4>Notification info</h4>
												</div>
												<div className="form-group has-error">
													<div className="row">
														<div className="col-md-4">
															<label htmlFor="notificationTitle">Notification title</label>
														</div>
														<div className="col-md-8">
															<input
																type="text"
																className={
																	error.notificationTitle
																		? "form-control is-invalid"
																		: "form-control"
																}
																name="notificationTitle"
																id="notificationTitle"
																value={this.state.notificationTitle}
																onChange={this.changeHandler}
																required={true}
																placeholder={``}
															/>
															{error.notificationTitle && (
																<div className="invalid-feedback">
																	{error.notificationTitle}
																</div>
															)}
														</div>
													</div>
												</div>

												<div className="form-group has-error">
													<div className="row">
														<div className="col-md-4">
															<label htmlFor="notificationText">Notification text</label>
														</div>
														<div className="col-md-8">
															<textarea
																type="text"
																className={
																	error.notificationText
																		? "form-control is-invalid"
																		: "form-control"
																}
																name="notificationText"
																id="notificationText"
																value={this.state.notificationText}
																onChange={this.changeHandler}
																required={true}
																placeholder={``}
															/>
															{error.notificationText && (
																<div className="invalid-feedback">
																	{error.notificationText}
																</div>
															)}
														</div>
													</div>
												</div>
											</div>

											<div className="form-actions text-right">
												<button
													type={isLoading ? "button" : "submit"}
													className="btn btn-primary is-invalid"
												>
													{" "}
													{isLoading ? (
														<i className="fas fa-spinner fa-spin"></i>
													) : (
														"Send Notification"
													)}{" "}
												</button>
											</div>
										</>
									);
								}
							})()}
						</div>
					</div>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	sendNotification,
	retrieveUsersTemp,
};
export default connect(mapStateToProps, mapDispatchToProps)(NewNotificationForm);
