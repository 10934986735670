import { combineReducers } from "redux";
import pageDataReducer from "./pageDataReducer";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import promoReducer from "./promoReducer";
import affiliateReducer from "./affiliateReducer";
import reportReducer from "./reportReducer";
import cardReducer from "./cardReducer";
import prospectorReducer from "./prospectorReducer";
import fileReducer from "./fileReducer";
import subscriptionReducer from "./subscriptionReducer";
import templateReducer from "./templateReducer";

const rootReducer = combineReducers({
	pageData: pageDataReducer,
	userData: userReducer,
	promoData: promoReducer,
	auth: authReducer,
	affiliateData: affiliateReducer,
	reportData: reportReducer,
	cardData: cardReducer,
	prospectorData: prospectorReducer,
	fileData: fileReducer,
	subscriptionData: subscriptionReducer,
	templateData: templateReducer,
});

export default rootReducer;
