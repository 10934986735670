import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import moment from "moment";
import QRCode from "qrcode.react";
import Modal from "react-modal";

import { logout } from "../../store/actions/authActions";

import bodyClassController from "../../store/utils/bodyClassController";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import SideMenu from "../../components/menu/sideMenu";
import PageBreadcrumb from "../../components/default/pageBreadcrumb";

import setPageData from "../../store/actions/setPageData";
import {
	retrieveUserDetails,
	retrieveUserCards,
} from "../../store/actions/userActions";
import { Link } from "react-router-dom";
import Icofont from "react-icofont";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		textAlign: "center",
	},
};

class UserDetailsPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			UserID: this.props.match.params.UserID,
			userGuaranteedAccessTill: "",
			error: {},
			isLoading: false,
			userCards: null,
			modalIsOpen: false,
			qrLink: null,
		};
	}
	changeHandler = (event) => {
		if (event.target.type == "checkbox") {
			this.setState({
				[event.target.name]: event.target.checked,
			});
		} else {
			this.setState({
				[event.target.name]: event.target.value,
			});
		}
	};

	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	resetFormData() {}

	setPageData() {
		this.props.setPageData({
			title: "User details",
			pageID: "users",
		});
	}

	loadUserDetails = async () => {
		let { UserID } = this.state;
		this.props.retrieveUserDetails({ UserID, U_Status: "any" });

		let _userCards = await this.props.retrieveUserCards({
			cardAuthor: UserID,
		});
		console.log("_userCards");
		console.log(_userCards);
		this.setState({
			userCards: _userCards,
		});
	};
	componentDidMount() {
		this.setPageData();
		this.loadUserDetails();
	}

	componentWilMount() {}

	openModal(qrLink) {
		this.setState({
			modalIsOpen: true,
			qrLink,
		});
	}

	afterOpenModal(qrLink) {
		this.setState({
			qrLink,
		});
	}

	closeModal() {
		this.setState({
			modalIsOpen: false,
		});
	}

	render() {
		this.props.bodyClassController(this.props.pageData);

		let { userData, pageData } = this.props;
		let { userGuaranteedAccessTill, isLoading, userCards, UserID } =
			this.state;

		let userDetails = userData?.userDetails;

		if (!userGuaranteedAccessTill) {
			let guaranteedAccessTill = userDetails?.guaranteedAccessTill;
			let format = "YYYY-MM-DD";
			let datum = Date.parse(guaranteedAccessTill);

			userGuaranteedAccessTill = moment(datum).format(format);

			// this.setState({ userGuaranteedAccessTill })
		}

		return (
			<>
				<Helmet>
					{" "}
					<title>{pageData.title}</title>{" "}
				</Helmet>
				<PrimaryMenu />

				<div className="container-fluid user-details">
					<div className="row page-body">
						<SideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{pageData.title}</h1>

										<Link
											to={`/users/${UserID}/update`}
											className="btn btn-primary ml-3"
										>
											<span className="icon-wrapper">
												<Icofont icon="icofont-plus-circle" />
											</span>
											<span className="icon-text">
												Edit
											</span>
										</Link>
									</div>
								</div>

								<div className="page-content">
									{userDetails ? (
										<div className="row">
											<div className="col-md-12 mt-4">
												<h5 className="card-title">
													Cards
												</h5>
												<div className="user-cards">
													<table className="table">
														{userCards
															? userCards.map(
																	(item) => {
																		return (
																			<tr>
																				<td>
																					<img
																						className="thumbnail"
																						src={
																							item.card_photo
																						}
																						alt=""
																					/>{" "}
																					{
																						item.card_name
																					}
																				</td>
																				<td>
																					{
																						item.card_email
																					}
																				</td>
																				<td>
																					{
																						item.card_phone
																					}
																				</td>
																				<td>
																					<QRCode
																						size={
																							14
																						}
																						onClick={() => {
																							this.openModal(
																								item.card_link
																							);
																						}}
																						className={`qr-icon`}
																						value={
																							item.card_link
																						}
																					/>
																					<a
																						href={
																							item.card_link
																						}
																						target="_blank"
																					>
																						{
																							item.card_link
																						}
																					</a>
																				</td>
																			</tr>
																		);
																	}
															  )
															: ""}
													</table>
													<Modal
														isOpen={
															this.state
																.modalIsOpen
														}
														onAfterOpen={
															this.state
																.afterOpenModal
														}
														onRequestClose={
															this.state
																.closeModal
														}
														style={customStyles}
														contentLabel="Example Modal"
													>
														<QRCode
															value={
																this.state
																	.qrLink
															}
														/>
														<br />
														<button
															className={`btn`}
															onClick={() => {
																this.closeModal();
															}}
														>
															close
														</button>
													</Modal>
												</div>
											</div>
										</div>
									) : (
										<div className="text-warning">
											Please wait...
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}

	openReOrderModal = (orderInfo) => {
		this.setState({
			ReOrderModalOpen: orderInfo,
		});
	};
	closeReOrderModal = () => {
		this.setState({
			ReOrderModalOpen: null,
		});
	};

	openNewTicketModal = (item) => {
		this.setState({
			NewTicketModalOpen: item.OrderID,
		});
	};

	closeNewTicketModal = () => {
		this.setState({
			NewTicketModalOpen: false,
		});
	};
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	bodyClassController,
	retrieveUserDetails,
	retrieveUserCards,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsPage);
