import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";
import ReactPaginate from "react-paginate";
import { ViewDate, ViewExactDate } from "../../utils/DateViewer";

import bodyClassController from "../../store/utils/bodyClassController";

import { retrieveUsers } from "../../store/actions/userActions";
import setPageData from "../../store/actions/setPageData";

class ShowUsers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ReOrderModalOpen: null,
			expandedItem: null,
			NewTicketModalOpen: false,
			accountType: "",
			autoRenew: false,
			paymentStatus: "activeSubscription",
			advanceFilter: false,
			dataLoading: false,
			orderBy: "date",
			orderSort: "DESC",
			promoCode: "",
			keyword: "",
			error: {},
			page: 1,
		};
	}
	changeHandler = async (event) => {
		let stateName, stateValue;
		if (event.target.type == "checkbox") {
			stateName = event.target.name;
			stateValue = event.target.checked;
		} else {
			stateName = event.target.name;
			stateValue = event.target.value;
		}

		this.setState(
			{
				[stateName]: stateValue,
			},
			() => {
				console.log("type", event.target.type);
				if (
					event.target.type === "checkbox" ||
					event.target.type === "select-one"
				) {
					this.submitHandler();
				}
			}
		);
	};

	submitHandler = async (event) => {
		if (event != null) {
			event.preventDefault();
		}

		this.setState({
			dataLoading: true,
		});

		let {
			page,
			promoCode,
			keyword,
			accountType,
			orderBy,
			orderSort,
			autoRenew,
			paymentStatus,
		} = this.state;
		await this.props.retrieveUsers({
			page,
			promoCode,
			keyword,
			accountType,
			orderBy,
			orderSort,
			paymentStatus,
			autoRenew,
		});

		this.props.setPageData({
			...this.props.pageData,
			totalUsers: this.props.userData.pagination.data_total,
		});

		this.setState({
			dataLoading: false,
		});
	};

	// set data sorting //
	setSorting = (orderBy, orderSort) => {
		this.setState({ orderBy, orderSort }, () => {
			this.submitHandler();
		});
	};

	componentDidMount() {
		this.setState(
			{
				paymentStatus: this.props.paymentStatus,
			},
			() => {
				this.submitHandler();
			}
		);
	}

	componentWilMount() {}

	handlePagination = (data) => {
		this.setState(
			{
				page: data.selected + 1,
			},
			() => {
				// this.props.retrieveUsers({ page: this.state.page })
				this.submitHandler();
			}
		);
	};

	render() {
		this.props.bodyClassController(this.props.pageData);

		let { userData } = this.props;

		let allUsers, userPagination, showPagination;
		if (
			userData &&
			userData.data &&
			this.props.userData.pagination.data_total > 0
		) {
			allUsers = userData.data.length ? userData.data : null;
			userPagination = userData.pagination ? userData.pagination : null;
			showPagination = userPagination.page_last > 1;
		}
		return (
			<div className="table-area table-responsive">
				<div className="data-viewer data-users-admin">
					<div className="viewer-header">
						<div className="header-item">
							<div className="item-left">
								<div className="item-name">Name</div>
								<div className="item-search">
									<input
										type="text"
										placeholder={`Search...`}
									/>
								</div>
							</div>

							<div className="item-action">
								<div className="action-search">
									<i className="fa icofont-search-2 "></i>
								</div>
								<div className="action-sort">
									<i className="fa fa-sort-amount-up"></i>
								</div>
							</div>
						</div>
						<div className="header-item">
							<div className="item-left">
								<div className="item-name">Email</div>
								<div className="item-search">
									<input
										type="text"
										placeholder={`Search...`}
									/>
								</div>
							</div>

							<div className="item-action">
								<div className="action-search">
									<i className="fa icofont-search-2 "></i>
								</div>
								<div className="action-sort">
									<i className="fa fa-sort-amount-up"></i>
								</div>
							</div>
						</div>
						<div className="header-item">
							<div className="item-left">
								<div className="item-name">Card URL</div>
								<div className="item-search">
									<input
										type="text"
										placeholder={`Search...`}
									/>
								</div>
							</div>

							<div className="item-action">
								<div className="action-search">
									<i className="fa icofont-search-2 "></i>
								</div>
								<div className="action-sort">
									<i className="fa fa-sort-amount-up"></i>
								</div>
							</div>
						</div>
						<div className="header-item">
							<div className="item-left">
								<div className="item-name">Start Date</div>
								<div className="item-search">
									<input
										type="text"
										placeholder={`Search...`}
									/>
								</div>
							</div>

							<div className="item-action">
								<div className="action-search">
									<i className="fa icofont-search-2 "></i>
								</div>
								<div className="action-sort">
									<i className="fa fa-sort-amount-up"></i>
								</div>
							</div>
						</div>
						<div className="header-item">
							<div className="item-left">
								<div className="item-name">Renewal Date</div>
								<div className="item-search">
									<input
										type="text"
										placeholder={`Search...`}
									/>
								</div>
							</div>

							<div className="item-action">
								<div className="action-search">
									<i className="fa icofont-search-2 "></i>
								</div>
								<div className="action-sort">
									<i className="fa fa-sort-amount-up"></i>
								</div>
							</div>
						</div>
					</div>
					<div className="viewer-content">
						{allUsers
							? allUsers.map((item) => {
									let userDisplayName = item.name
										? item.name
										: `${item.firstName} ${item.lastName}`;

									let refDisplayName = item.referBy
										? `${item.refFirstName} ${item.refLastName}`
										: "--";
									let promoCode = item.promoCode
										? item.promoCode
										: "--";
									return (
										<div className="viewer-data">
											<div className="data-single data-name">
												{" "}
												<div className="single-content">
													<Link
														to={`/users/${item.UserID}`}
													>
														{userDisplayName}
													</Link>
												</div>
											</div>
											<div className="data-single data-email">
												<div className="single-content">
													{item.email}
												</div>
											</div>
											<div className="data-single data-cards">
												<div className="single-content card-links">
													{item.cards
														? item.cards.map(
																(card) => {
																	return (
																		<a
																			href={
																				card.card_link
																			}
																			target={`_blank`}
																		>
																			{
																				card.card_link
																			}
																		</a>
																	);
																}
														  )
														: ""}
												</div>
											</div>
											<div className="data-single data-join-on">
												<div className="single-content">
													<ViewExactDate
														date={item.date}
														format="MM-DD-YYYY"
													/>
												</div>
											</div>
											<div className="data-single data-expire-date">
												<div className="single-content">
													{item.premiumExpireDate !=
													null ? (
														<ViewExactDate
															date={
																item.premiumExpireDate
															}
															format="MM-DD-YYYY"
														/>
													) : (
														"--"
													)}
												</div>
											</div>
										</div>
									);
							  })
							: ""}
					</div>
				</div>
				<div className="section-pagination">
					<font>
						Showing {userPagination?.data_start} to{" "}
						{userPagination?.data_end} out of{" "}
						{userPagination?.data_total}
					</font>
					{showPagination && (
						<nav className="pagination-nav" aria-label="...">
							<ReactPaginate
								previousLabel={"previous"}
								nextLabel={"next"}
								breakLabel={"..."}
								breakClassName={"break-me"}
								pageCount={userPagination.page_last}
								initialPage={userPagination.page - 1}
								marginPagesDisplayed={2}
								pageRangeDisplayed={5}
								containerClassName={"pagination"}
								previousClassName={"page-item"}
								nextClassName={"page-item"}
								pageClassName={"page-item"}
								subContainerClassName={"pages pagination"}
								activeClassName={"active"}
								pageLinkClassName={"page-link"}
								previousLinkClassName={"page-link"}
								nextLinkClassName={"page-link"}
								onPageChange={this.handlePagination}
							/>
						</nav>
					)}
				</div>
			</div>
		);
	}

	openReOrderModal = (orderInfo) => {
		this.setState({
			ReOrderModalOpen: orderInfo,
		});
	};
	closeReOrderModal = () => {
		this.setState({
			ReOrderModalOpen: null,
		});
	};

	openNewTicketModal = (item) => {
		this.setState({
			NewTicketModalOpen: item.OrderID,
		});
	};

	closeNewTicketModal = () => {
		this.setState({
			NewTicketModalOpen: false,
		});
	};
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
});
const mapDispatchToProps = {
	logout,
	bodyClassController,
	retrieveUsers,
	setPageData,
};
export default connect(mapStateToProps, mapDispatchToProps)(ShowUsers);
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
