import axios from "axios";
import * as Types from "./types";

import { manageError } from "../utils/axiosResponseError";

export const retrieveTemplateAction = (postData) => async (dispatch) => {
	let responseData;
	await axios
		.post("card/template/list", postData)
		.then((res) => {
			responseData = res.data;
			dispatch({
				type: Types.TEMPLATE_RETRIEVE,
				payload: {
					response: responseData,
					error: {},
				},
			});
		})
		.catch((error) => {
			manageError(error);
		});

	return responseData;
};
