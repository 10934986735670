import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import bodyClassController from "../../store/utils/bodyClassController";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import SideMenu from "../../components/menu/sideMenu";
import PageBreadcrumb from "../../components/default/pageBreadcrumb";

import setPageData from "../../store/actions/setPageData";
import ShowUsersAffiliate from "../../components/user/showUsersAffilaite";
import AffiliateSideMenu from "../../components/menu/affiliateSideMenu";

class AffiliateUsersPremiumPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: {},
		};
	}

	setPageData() {
		this.props.setPageData({
			title: "Premium users",
			totalUsers: "...",
			pageID: "users_premium",
		});
	}

	componentDidMount() {
		this.setPageData();
	}

	componentWilMount() {}

	render() {
		this.props.bodyClassController(this.props.pageData);
		let { pageData } = this.props;

		return (
			<>
				<Helmet>
					{" "}
					<title>{pageData.title}</title>{" "}
				</Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<AffiliateSideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{`${pageData.title} (${pageData.totalUsers})`}</h1>
									</div>
								</div>

								<div className="page-content">
									<ShowUsersAffiliate
										paymentStatus={`hasPremiumPlan`}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	bodyClassController,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AffiliateUsersPremiumPage);
