import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";
import ReactPaginate from "react-paginate";
import Icofont from "react-icofont";

import bodyClassController from "../../store/utils/bodyClassController";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import SideMenu from "../../components/menu/sideMenu";
import PageBreadcrumb from "../../components/default/pageBreadcrumb";

import setPageData from "../../store/actions/setPageData";
import { retrieveAffiliates } from "../../store/actions/userActions";
import Modal from "react-modal";
import NewPromoForm from "../../components/promoCode/NewPromoForm";
import NewReportForm from "../../components/report/NewReportForm";
import { retrieveReport } from "../../store/actions/reportsActions";
import { ViewExactDate } from "../../utils/DateViewer";

class ReportsPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalIsOpen: null,
			expandedItem: null,
			user: "",
			password: "",
			affiliate: true,
			keyword: "",
			error: {},
			page: 1,
		};
	}
	changeHandler = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	submitHandler = (event) => {
		if (event != null) {
			event.preventDefault();
		}

		let { page, keyword, affiliate } = this.state;

		this.props.retrieveReport({
			page,
			keyword,
		});
	};

	setPageData() {
		this.props.setPageData({
			title: "Reports (Commissions)",
			pageID: "reports",
		});
	}

	componentDidMount() {
		this.setPageData();
		this.submitHandler();
	}

	componentWilMount() {}

	handlePagination = (data) => {
		this.setState(
			{
				page: data.selected + 1,
			},
			() => {
				this.submitHandler();
			}
		);
	};

	render() {
		this.props.bodyClassController(this.props.pageData);

		let { reportData, pageData } = this.props;
		let allReports, userPagination, showPagination;
		if (reportData && reportData.data) {
			allReports = reportData.data.length ? reportData.data : null;
			userPagination = reportData.pagination
				? reportData.pagination
				: null;

			showPagination = userPagination.page_last > 1 ? true : false;
		}

		return (
			<>
				<Helmet>
					{" "}
					<title>{pageData.title}</title>{" "}
				</Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<SideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{pageData.title}</h1>
									</div>
									<span
										className="btn btn-primary ml-3"
										onClick={() => this.openModal()}
									>
										<span className="icon-wrapper">
											<Icofont icon="icofont-plus-circle" />
										</span>
										<span className="icon-text">
											{" "}
											Create
										</span>
									</span>
									{NewReportModal(this)}
								</div>

								<div className="page-content">
									<div className="table-area table-responsive">
										<div className="data-viewer data-reports-admin">
											<div className="viewer-header">
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Report name
														</div>
														<div className="item-search">
															<input
																type="text"
																placeholder={`Search...`}
															/>
														</div>
													</div>

													<div className="item-action">
														<div className="action-search">
															<i className="fa icofont-search-2 "></i>
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Start date
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															End date
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Agents
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Uploaded
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Commissions
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Paid
														</div>
													</div>
												</div>
											</div>

											<div className="viewer-content">
												{allReports
													? allReports.map((item) => {
															let userDisplayName =
																item.name
																	? item.name
																	: `${item.firstName} ${item.lastName}`;

															let refDisplayName =
																item.referBy
																	? `${item.refFirstName} ${item.refLastName}`
																	: "--";
															let promoCode =
																item.promoCode
																	? item.promoCode
																	: "--";
															return (
																<div className="viewer-data">
																	<div className="data-single data-title">
																		<div
																			className="single-content"
																			data-label={`Title`}
																		>
																			<Link
																				to={`/reports/${item.id}`}
																			>
																				{
																					item.title
																				}
																			</Link>
																		</div>
																	</div>
																	<div className="data-single data-range-from">
																		<div
																			className="single-content"
																			data-label={`Range from`}
																		>
																			<ViewExactDate
																				date={
																					item.rangeFrom
																				}
																				format="MM-DD-YYYY"
																			/>
																		</div>
																	</div>
																	<div className="data-single data-range-to">
																		<div
																			className="single-content"
																			data-label={`Range to`}
																		>
																			<ViewExactDate
																				date={
																					item.rangeTo
																				}
																				format="MM-DD-YYYY"
																			/>
																		</div>
																	</div>
																	<div className="data-single data-agent-total">
																		<div
																			className="single-content"
																			data-label={`Agents`}
																		>
																			{
																				item.agentTotal
																			}
																		</div>
																	</div>
																	<div className="data-single data-approved-total">
																		<div
																			className="single-content"
																			data-label={`Approved`}
																		>
																			{
																				item.approvedTotal
																			}
																		</div>
																	</div>
																	<div className="data-single data-commission-total">
																		<div
																			className="single-content"
																			data-label={`Commissions`}
																		>
																			{parseFloat(
																				item.commissionTotal
																			) >
																			0
																				? "$" +
																				  parseFloat(
																						item.commissionTotal
																				  ).toFixed(
																						2
																				  )
																				: "0"}
																		</div>
																	</div>
																	<div className="data-single data-paid-total">
																		<div
																			className="single-content"
																			data-label={`Paid`}
																		>
																			{parseFloat(
																				item.paidTotal
																			) >
																			0
																				? "$" +
																				  parseFloat(
																						item.paidTotal
																				  ).toFixed(
																						2
																				  )
																				: "0"}
																		</div>
																	</div>
																</div>
															);
													  })
													: ""}
											</div>
										</div>

										{showPagination ? (
											<div className="section-pagination">
												<font>
													Showing{" "}
													{userPagination.data_start}{" "}
													to {userPagination.data_end}{" "}
													out of{" "}
													{userPagination.data_total}
												</font>
												<nav
													className="pagination-nav"
													aria-label="..."
												>
													<ReactPaginate
														previousLabel={
															"previous"
														}
														nextLabel={"next"}
														breakLabel={"..."}
														breakClassName={
															"break-me"
														}
														pageCount={
															userPagination.page_last
														}
														initialPage={
															userPagination.page -
															1
														}
														marginPagesDisplayed={2}
														pageRangeDisplayed={5}
														containerClassName={
															"pagination"
														}
														previousClassName={
															"page-item"
														}
														nextClassName={
															"page-item"
														}
														pageClassName={
															"page-item"
														}
														subContainerClassName={
															"pages pagination"
														}
														activeClassName={
															"active"
														}
														pageLinkClassName={
															"page-link"
														}
														previousLinkClassName={
															"page-link"
														}
														nextLinkClassName={
															"page-link"
														}
														onPageChange={
															this
																.handlePagination
														}
													/>
												</nav>
											</div>
										) : (
											""
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);

		function NewReportModal(that) {
			return (
				<Modal
					isOpen={that.state.modalIsOpen}
					contentLabel="Example Modal"
				>
					<div className="basic-modal">
						<div className="modal-header">
							<h3>Upload Statement</h3>
							<button
								className="btn close-modal"
								type={`button`}
								onClick={() => {
									that.closeModal();
								}}
							>
								<i className="icofont-close-line"></i>
							</button>
						</div>
						<div className="modal-content">
							<NewReportForm />
						</div>
					</div>
				</Modal>
			);
		}
	}

	openModal() {
		this.setState({
			modalIsOpen: true,
		});
	}
	closeModal() {
		this.setState({
			modalIsOpen: false,
		});
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	reportData: state.reportData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	bodyClassController,
	retrieveAffiliates,
	retrieveReport,
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
