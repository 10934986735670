import * as Types from '../actions/types'

const init = {
	data: {},
	pagination: {},
	error: {},
	selected: {},
	success: '',
}

const affiliateReducer = (state = init, action) => {
	switch (action.type) {

		case Types.AFFILIATE_RETRIEVE: {
			return {
				...state,
				data: action.payload.response.data,
				pagination: action.payload.response.pagination,
				selected: {},
				error: {},
			}
		}

		case Types.AFFILIATE_NEW: {
			if (Object.keys(state.data).length) {
				if (action.payload.userInfo) {
					state.data.unshift(action.payload.userInfo)
				}
			} else {
				state.data = action.payload.userInfo
			}

			return {
				...state,
			}
		}

		default: return state
	}
}

export default affiliateReducer