import axios from "axios";
import * as Types from "./types";

import { manageError } from "../utils/axiosResponseError";
import { showSuccess } from "../utils/configToast";

export const insertNewFileAction = (user) => async (dispatch) => {
	let responseData;
	await axios
		.post("essential/file/insert", user)
		.then((res) => {
			responseData = res.data;
			dispatch({
				type: Types.FILES_SET,
				payload: {
					fileInfo: responseData.data,
					error: {},
				},
			});
			showSuccess("Successfully added");
		})
		.catch((error) => {
			manageError(error);
		});

	return responseData;
};

export const retrieveFileAction = (postData) => async (dispatch) => {
	let responseData;
	await axios
		.post("essential/file/list", postData)
		.then((res) => {
			responseData = res.data;
			dispatch({
				type: Types.FILES_RETRIEVE,
				payload: {
					response: responseData,
					error: {},
				},
			});
		})
		.catch((error) => {
			manageError(error);
		});

	return responseData;
};
export const retrieveFileTempAction = (postData) => async (dispatch) => {
	let responseData;
	await axios
		.post("essential/file/list", postData)
		.then((res) => {
			responseData = res.data;
		})
		.catch((error) => {
			manageError(error);
		});

	return responseData;
};
