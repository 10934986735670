import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import bodyClassController from "../../store/utils/bodyClassController";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import SideMenu from "../../components/menu/sideMenu";
import PageBreadcrumb from "../../components/default/pageBreadcrumb";

import setPageData from "../../store/actions/setPageData";
import Icofont from "react-icofont";
import Modal from "react-modal";
import ReactPaginate from "react-paginate";
import { retrieveUsers } from "../../store/actions/userActions";
import NewBizAccountForm from "../../components/business-account/NewBizAccountForm";
import { ViewExactDate } from "../../utils/DateViewer";
import UpdateBizAccountForm from "../../components/business-account/UpdateBizAccountForm";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import UserFiles from "../../components/user/UserFiles";
import BizSubscriptions from "../../components/business-account/BizSubscriptions";
import UserTemplates from "../../components/user/UserTemplates";
import UserCards from "../../components/user/UserCards";

class UsersBusinessPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: {},
		};
	}

	setPageData() {
		this.props.setPageData({
			title: "Business accounts",
			totalUsers: "...",
			pageID: "users_business",
		});
	}

	componentDidMount() {
		this.setPageData();
		this.submitHandler();
	}

	componentWilMount() {}

	submitHandler = async (event) => {
		if (event != null) {
			event.preventDefault();
		}

		let { page, keyword } = this.state;

		let responseData = await this.props.retrieveUsers({
			page,
			keyword,
			isBusinessAccount: true,
			getReferral: true,
		});

		let totalUsers;
		if (responseData) {
			let { pagination } = responseData;
			totalUsers = pagination ? pagination.data_total : null;
		}

		this.props.setPageData({
			...this.props.pageData,
			totalUsers,
		});
	};

	render() {
		this.props.bodyClassController(this.props.pageData);

		let { userData, pageData } = this.props;
		let { keyword, expandedItem } = this.state;
		let allUsers, userPagination, showPagination;
		if (userData && userData.data) {
			allUsers = userData.data.length ? userData.data : null;
			userPagination = userData.pagination ? userData.pagination : null;

			showPagination = userPagination.page_last > 1 ? true : false;
		}

		return (
			<>
				<Helmet>
					{" "}
					<title>{pageData.title}</title>{" "}
				</Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<SideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{`${pageData.title} (${pageData.totalUsers})`}</h1>
									</div>

									<span
										className="btn btn-primary ml-3"
										onClick={() => this.openModal()}
									>
										<span className="icon-wrapper">
											<Icofont icon="icofont-plus-circle" />
										</span>
										<span className="icon-text">
											{" Add New Business Account"}
										</span>
									</span>
									{NewBizAccModal(this)}
								</div>

								<div className="page-content">
									<div className="table-area table-responsive">
										<div className="data-viewer data-business-users-admin">
											<div className="viewer-header">
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Business Name
														</div>
														<div className="item-search">
															<input
																type="text"
																placeholder={`Search...`}
															/>
														</div>
													</div>

													<div className="item-action">
														<div className="action-search">
															<i className="fa icofont-search-2 " />
														</div>
														<div className="action-sort">
															<i className="fa fa-sort-amount-up" />
														</div>
													</div>
												</div>

												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Email
														</div>
														<div className="item-search">
															<input
																type="text"
																placeholder={`Search...`}
															/>
														</div>
													</div>

													<div className="item-action">
														<div className="action-search">
															<i className="fa icofont-search-2 " />
														</div>
														<div className="action-sort">
															<i className="fa fa-sort-amount-up" />
														</div>
													</div>
												</div>

												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Contact name
														</div>
													</div>

													<div className="item-action">
														<div className="action-sort">
															<i className="fa fa-sort-amount-up" />
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Start date
														</div>
													</div>

													<div className="item-action">
														<div className="action-sort">
															<i className="fa fa-sort-amount-up" />
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Renewal date
														</div>
													</div>

													<div className="item-action">
														<div className="action-sort">
															<i className="fa fa-sort-amount-up" />
														</div>
													</div>
												</div>
											</div>

											<div className="viewer-content">
												{allUsers
													? allUsers.map((item) => {
															let userDisplayName =
																item.firstName;

															return (
																<div className="viewer-data">
																	<div className="data-single data-name">
																		<div className="single-content">
																			<span
																				className={`a`}
																				onClick={() =>
																					this.setState(
																						{
																							updateModalIsOpen: true,
																							selectedUser:
																								item.UserID,
																						}
																					)
																				}
																			>
																				{
																					userDisplayName
																				}
																			</span>
																			<br />
																			{
																				item.UserID
																			}
																		</div>
																	</div>
																	<div className="data-single data-users-trial">
																		<div
																			className="single-content"
																			data-label={`Trial users`}
																		>
																			{
																				item.email
																			}
																		</div>
																	</div>
																	<div className="data-single data-users-free">
																		<div
																			className="single-content"
																			data-label={`Free users`}
																		>
																			{
																				item.lastName
																			}
																		</div>
																	</div>
																	<div className="data-single data-users-premium">
																		<div
																			className="single-content"
																			data-label={`Start date`}
																		>
																			<ViewExactDate
																				date={
																					item.date
																				}
																				format="MM-DD-YYYY"
																			/>
																		</div>
																	</div>
																	<div className="data-single data-users-enterprise">
																		<div
																			className="single-content"
																			data-label={`Enterprise users`}
																		>
																			{
																				item.enterpriseUsers
																			}
																		</div>
																	</div>
																</div>
															);
													  })
													: ""}

												{UpdateAgentModal(this)}
											</div>
										</div>

										{showPagination ? (
											<div className="section-pagination">
												<font>
													Showing{" "}
													{userPagination.data_start}{" "}
													to {userPagination.data_end}{" "}
													out of{" "}
													{userPagination.data_total}
												</font>
												<nav
													className="pagination-nav"
													aria-label="..."
												>
													<ReactPaginate
														previousLabel={
															"previous"
														}
														nextLabel={"next"}
														breakLabel={"..."}
														breakClassName={
															"break-me"
														}
														pageCount={
															userPagination.page_last
														}
														initialPage={
															userPagination.page -
															1
														}
														marginPagesDisplayed={2}
														pageRangeDisplayed={5}
														containerClassName={
															"pagination"
														}
														previousClassName={
															"page-item"
														}
														nextClassName={
															"page-item"
														}
														pageClassName={
															"page-item"
														}
														subContainerClassName={
															"pages pagination"
														}
														activeClassName={
															"active"
														}
														pageLinkClassName={
															"page-link"
														}
														previousLinkClassName={
															"page-link"
														}
														nextLinkClassName={
															"page-link"
														}
														onPageChange={
															this
																.handlePagination
														}
													/>
												</nav>
											</div>
										) : (
											""
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);

		function NewBizAccModal(that) {
			return (
				<Modal
					isOpen={that.state.modalIsOpen}
					contentLabel="Example Modal"
				>
					<div className="basic-modal">
						<div className="modal-header">
							<h3>New Business Setup</h3>
							<button
								className="btn close-modal"
								type={`button`}
								onClick={() => {
									that.closeModal();
								}}
							>
								<i className="icofont-close-line" />
							</button>
						</div>
						<div className="modal-content">
							<NewBizAccountForm />
						</div>
					</div>
				</Modal>
			);
		}

		function UpdateAgentModal(that) {
			let { selectedUser: userID } = that.state;
			return (
				<Modal
					isOpen={that.state.updateModalIsOpen}
					contentLabel="Example Modal"
				>
					<div className="basic-modal">
						<div className="modal-header">
							<h3>Account details</h3>
							<button
								className="btn close-modal"
								type={`button`}
								onClick={() => {
									that.submitHandler();
									that.setState({
										updateModalIsOpen: false,
									});
								}}
							>
								<i className="icofont-close-line" />
							</button>
						</div>
						<div className="modal-content">
							<Tabs>
								<TabList>
									<Tab>Profile</Tab>
									<Tab>Files</Tab>
									<Tab>Cards</Tab>
									<Tab>Subscription</Tab>
									<Tab>Templates</Tab>
								</TabList>

								<TabPanel>
									<UpdateBizAccountForm userID={userID} />
								</TabPanel>
								<TabPanel>
									<UserFiles userID={userID} />
								</TabPanel>
								<TabPanel>
									<UserCards userID={userID} />
								</TabPanel>
								<TabPanel>
									<BizSubscriptions userID={userID} />
								</TabPanel>
								<TabPanel>
									<UserTemplates userID={userID} />
								</TabPanel>
							</Tabs>
						</div>
					</div>
				</Modal>
			);
		}
	}

	openModal() {
		this.setState({
			modalIsOpen: true,
		});
	}
	closeModal() {
		this.setState({
			modalIsOpen: false,
		});
		this.submitHandler();
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	bodyClassController,
	retrieveUsers,
};
export default connect(mapStateToProps, mapDispatchToProps)(UsersBusinessPage);
