import React from "react";
import { connect } from "react-redux";
import { sendCardSetupMailAction } from "../../store/actions/cardActions";
// import {insertNewCardAction} from "../../store/actions/cardActions";

class SendCardSetupMailForm extends React.Component {
	state = {
		error: {},
		isLoading: false,
		title: "",
		rangeFrom: "",
		rangeTo: "",
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	resetFormData = () => {
		this.setState({
			email: "",
		});
	};

	submitSendMailHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		let { email, cardID, subject, message } = this.state;
		await this.props.sendCardSetupMailAction({
			cardID,
			email,
			subject,
			message,
		});
		this.stopLoading();
	};

	async componentDidMount() {
		let { cardDetails } = this.props;

		// set default info if data provided
		if (cardDetails) {
			this.setState({
				cardDetails,
				cardID: cardDetails.ID,
				email: cardDetails.card_email,
				subject: "Install NextCard App To access your digital card",
				message:
					"Hello,\nOpen the link below from your smartphone to install the NextCard app. You will automatically log in after the install completed.",
			});
		}
	}

	render() {
		let { isLoading, error, cardDetails } = this.state;

		return (
			<form onSubmit={this.submitSendMailHandler} action="">
				<div className="row insert-new">
					<div className="col-md-12">
						<div className="single-section">
							{/*<div className="section-title">*/}
							{/*	<h5>Enter email</h5>*/}
							{/*</div>*/}
							<div className="row">
								<div className="col-md-6">
									<div className="form-group has-error">
										<label htmlFor="email">
											Enter email
										</label>
										<input
											type="email"
											className={
												error.email
													? "form-control is-invalid"
													: "form-control"
											}
											name="email"
											id="email"
											required={1}
											value={this.state.email}
											onChange={this.changeHandler}
											placeholder={`Ex. emplyename@domain.com`}
										/>
										{error.email && (
											<div className="invalid-feedback">
												{error.email}
											</div>
										)}
									</div>
								</div>

								<div className="col-md-6">
									<div className="form-group has-error">
										<label htmlFor="subject">Subject</label>
										<input
											type="subject"
											className={
												error.subject
													? "form-control is-invalid"
													: "form-control"
											}
											name="subject"
											id="subject"
											required={1}
											value={this.state.subject}
											onChange={this.changeHandler}
											placeholder={`Ex. emplyename@domain.com`}
										/>
										{error.subject && (
											<div className="invalid-feedback">
												{error.subject}
											</div>
										)}
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group has-error">
										<label htmlFor="message">Message</label>
										<textarea
											name="message"
											id="message"
											onChange={this.changeHandler}
											className={`form-control`}
											cols="30"
											rows="3"
											value={this.state.message}
										/>
										{error.message && (
											<div className="invalid-feedback">
												{error.message}
											</div>
										)}
									</div>
								</div>
							</div>
							<p>
								Lorem ipsum dolor sit amet, consectetur
								adipisicing elit. A, ad aperiam consequatur
								doloremque ducimus eum exercitationem explicabo
								facere, hic id, ipsa itaque iusto molestiae
								natus odit sunt veniam! Tempore, velit.
							</p>
						</div>
					</div>
					<div className="col-md-12">
						<div className="single-section">
							<div className=" text-right">
								<button
									type={isLoading ? "button" : "submit"}
									className="btn btn-primary is-invalid"
								>
									{" "}
									{isLoading ? (
										<i className="fas fa-spinner fa-spin" />
									) : (
										"Send mail"
									)}{" "}
								</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	sendCardSetupMailAction,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SendCardSetupMailForm);
