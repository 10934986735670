import React from "react";
import TimeAgo from "react-timeago";
import moment from "moment";

export class ViewExactDate extends React.Component {
	render() {
		let format = this.props.format ? this.props.format : "Do MMM LT";
		let datum = Date.parse(this.props.date);

		let exactTime = moment(datum).format(format);

		return <span className="date-exact-view">{exactTime}</span>;
	}
}

export class ViewDate extends React.Component {
	render() {
		let datum = Date.parse(this.props.date);
		let exactTime = moment(datum).format("LT Do MMM YY");

		return (
			<div className="date-view">
				<font className="exact-time">{exactTime}</font>
				<TimeAgo date={this.props.date} className="time-ago" />
			</div>
		);
	}
}
