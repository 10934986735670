import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import bodyClassController from "../../store/utils/bodyClassController";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import SideMenu from "../../components/menu/sideMenu";
import PageBreadcrumb from "../../components/default/pageBreadcrumb";

import setPageData from "../../store/actions/setPageData";
import ShowUsers from "../../components/user/showUsers";

class UsersAllPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: {},
		};
	}

	setPageData() {
		this.props.setPageData({
			title: "All users",
			pageID: "home_page",
			totalUsers: "...",
		});
	}

	componentDidMount() {
		this.setPageData();
	}

	componentWilMount() {}

	render() {
		this.props.bodyClassController(this.props.pageData);
		let { pageData, userData } = this.props;

		let userSummery;

		if (userData) {
			userSummery = userData.summery;
		}

		return (
			<>
				<Helmet>
					{" "}
					<title>{pageData.title}</title>{" "}
				</Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<SideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{`${pageData.title} (${pageData.totalUsers})`}</h1>
									</div>
									{userSummery ? (
										<div className="users-summery">
											<div className="summery-single">
												<div className="count">
													{userSummery.trialUsers}
												</div>
												<div className="type">
													Trial
												</div>
											</div>
											<div className="summery-single">
												<div className="count">
													{userSummery.freeUsers}
												</div>
												<div className="type">Free</div>
											</div>
											<div className="summery-single">
												<div className="count">
													{userSummery.premiumUsers}
												</div>
												<div className="type">
													Premium
												</div>
											</div>
											<div className="summery-single">
												<div className="count">
													{
														userSummery.enterpriseUsers
													}
												</div>
												<div className="type">
													Enterprise
												</div>
											</div>
											<div className="summery-single">
												<div className="count">
													{userSummery.businessUsers}
												</div>
												<div className="type">
													Business
												</div>
											</div>
											<div className="summery-single">
												<div className="count">
													{userSummery.cancelledUsers}
												</div>
												<div className="type">
													Cancelled
												</div>
											</div>
											<div className="summery-single">
												<div className="count">
													{userSummery.noPlanTaken}
												</div>
												<div className="type">
													Inactive
												</div>
											</div>
										</div>
									) : (
										""
									)}
								</div>

								<div className="page-content">
									<ShowUsers paymentStatus={null} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	bodyClassController,
};
export default connect(mapStateToProps, mapDispatchToProps)(UsersAllPage);
