import axios from "axios";
import * as Types from "./types";

import { manageError } from "../utils/axiosResponseError";
import { showSuccess } from "../utils/configToast";

export const insertNewCardAction = (user) => async (dispatch) => {
	let responseData;
	await axios
		.post("card/insert", user)
		.then((res) => {
			responseData = res.data;
			// dispatch({
			// 	type: Types.CARD_INSERT,
			// 	payload: {
			// 		user: userData,
			// 		error: {},
			// 	},
			// });
			showSuccess("Successfully inserted");
		})
		.catch((error) => {
			manageError(error);
		});

	return responseData;
};

export const updateCardAction = (user) => async (dispatch) => {
	let responseData;
	await axios
		.post("card/update", user)
		.then((res) => {
			responseData = res.data;
			// dispatch({
			// 	type: Types.CARD_INSERT,
			// 	payload: {
			// 		user: userData,
			// 		error: {},
			// 	},
			// });
			showSuccess("Successfully updated");
		})
		.catch((error) => {
			manageError(error);
		});

	return responseData;
};

export const sendCardSetupMailAction = (user) => async (dispatch) => {
	let responseData;
	await axios
		.post("card/send-setup-mail", user)
		.then((res) => {
			responseData = res.data;
			showSuccess("Email sent");
		})
		.catch((error) => {
			manageError(error);
		});

	return responseData;
};

export const retrieveCardMinAction = (postData) => async (dispatch) => {
	let responseData;
	await axios
		.post("card/list", postData)
		.then((res) => {
			responseData = res.data.data[0];
		})
		.catch((error) => {
			manageError(error);
		});

	return responseData;
};
export const retrieveCardAction = (postData) => async (dispatch) => {
	let responseData;
	await axios
		.post("card/list", postData)
		.then((res) => {
			responseData = res.data;
			dispatch({
				type: Types.CARD_RETRIEVE,
				payload: {
					response: responseData,
					error: {},
				},
			});
		})
		.catch((error) => {
			manageError(error);
		});

	return responseData;
};
