import axios from "axios";
import * as Types from "./types";

import { showSuccess, showError } from "../utils/configToast";
import { manageError } from "../utils/axiosResponseError";

export const retrieveUsers = (data) => async (dispatch) => {
	let responseData;
	await axios
		.post("/user/list", data)
		.then((res) => {
			responseData = res.data;
			dispatch({
				type: Types.USER_RETRIEVE,
				payload: {
					response: responseData,
					error: {},
				},
			});
		})
		.catch((error) => {
			manageError(error);
		});
	return responseData;
};
export const retrieveSnapshotUsers = (data) => async (dispatch) => {
	await axios
		.post("/user/snapshot/list", data)
		.then((res) => {
			let responseData = res.data;
			dispatch({
				type: Types.USER_RETRIEVE,
				payload: {
					response: responseData,
					error: {},
				},
			});
		})
		.catch((error) => {
			manageError(error);
		});
};

export const retrieveUsersTemp = (data) => async (dispatch) => {
	let responseData;
	await axios
		.post("/user/list", data)
		.then((res) => {
			responseData = res.data;
		})
		.catch((error) => {
			manageError(error);
		});
	return responseData;
};

export const retrieveUserDetails = (data) => (dispatch) => {
	dispatch({
		type: Types.USER_DETAILS,
		userData: null,
	});

	axios
		.post("/user/list", data)
		.then((res) => {
			let userData = res?.data?.data[0];

			dispatch({
				type: Types.USER_DETAILS,
				userData,
			});
		})
		.catch((error) => {
			manageError(error);
		});
};

export const retrieveUserCards = (data) => async (dispatch) => {
	return axios
		.post("/card/list", data)
		.then((res) => {
			return res?.data?.data;
		})
		.catch((error) => {
			manageError(error);
		});
};

export const retrieveAffiliates = (data) => (dispatch) => {
	axios
		.post("/user/list", data)
		.then((res) => {
			let responseData = res.data;

			dispatch({
				type: Types.AFFILIATE_RETRIEVE,
				payload: {
					response: responseData,
					error: {},
				},
			});
		})
		.catch((error) => {
			manageError(error);
		});
};

export const registerAffiliates =
	(data, history, stopLoading, resetFormData) => (dispatch) => {
		axios
			.post("/user/register-affiliate", data)
			.then((res) => {
				let userInfo = res.data.data;

				stopLoading();
				resetFormData();

				dispatch({
					type: Types.AFFILIATE_NEW,
					payload: {
						userInfo,
						error: {},
					},
				});
				showSuccess("Successfully created");
			})
			.catch((error) => {
				stopLoading();
				manageError(error);
			});
	};

export const registerBusiness = (data, history) => async (dispatch) => {
	await axios
		.post("/user/register-business", data)
		.then((res) => {
			let userInfo = res.data.data;

			// dispatch({
			// 	type: Types.AFFILIATE_NEW, payload: {
			// 		userInfo, error: {},
			// 	},
			// });
			showSuccess("Successfully created");
		})
		.catch((error) => {
			manageError(error);
		});
};
export const updateBusiness = (data, history) => async (dispatch) => {
	await axios
		.post("/user/update-business", data)
		.then((res) => {
			let userInfo = res.data.data;
			showSuccess("Successfully created");
		})
		.catch((error) => {
			manageError(error);
		});
};

export const retrieveAgentSummeryTemp = (data) => async (dispatch) => {
	let responseData;
	await axios
		.post("/user/affiliate/summery", data)
		.then((res) => {
			responseData = res.data;
		})
		.catch((error) => {
			manageError(error);
		});
	return responseData;
};

export const updateUserAction =
	(data, history, stopLoading, resetFormData) => async (dispatch) => {
		await axios
			.post("/user/update", data)
			.then((res) => {
				let userInfo = res.data.data;

				if (stopLoading) {
					stopLoading();
				}

				if (resetFormData) {
					resetFormData();
				}

				showSuccess("Successfully updated");
			})
			.catch((error) => {
				if (stopLoading) {
					stopLoading();
				}
				manageError(error);
			});
	};
export const updateAffiliateAction =
	(data, history, stopLoading, resetFormData) => async (dispatch) => {
		await axios
			.post("/user/affiliate/update", data)
			.then((res) => {
				let userInfo = res.data.data;

				stopLoading();
				resetFormData();

				showSuccess("Successfully updated");
			})
			.catch((error) => {
				stopLoading();
				manageError(error);
			});
	};

export const deleteUserAction =
	(data, history, stopLoading, resetFormData) => (dispatch) => {
		axios
			.post("/user/delete", data)
			.then((res) => {
				let userInfo = res.data.data;

				stopLoading();
				showSuccess("Successfully deleted");
				history.push("/users");
			})
			.catch((error) => {
				stopLoading();
				manageError(error);
			});
	};



export const sendNotification = (data) => async (dispatch) => {
	let responseData;
	await axios
		.post("/user/admin/send-notification", data)
		.then((res) => {
			responseData = res.data;
			showSuccess(responseData.message);
		})
		.catch((error) => {
			manageError(error);
		});
	return responseData;
};
