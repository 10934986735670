import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authActions";

import bodyClassController from "../../store/utils/bodyClassController";
import PageBreadcrumb from "../../components/default/pageBreadcrumb";

import setPageData from "../../store/actions/setPageData";
import AffiliateSideMenu from "../../components/menu/affiliateSideMenu";
import PrimaryMenu from "../../components/menu/headerPrimaryMenu";
import Modal from "react-modal";
import {
	deletePromoCodes,
	insertPromoCodes,
	retrievePromoCodes,
} from "../../store/actions/promoCodesActions";
import Icofont from "react-icofont";
import NewPromoForm from "../../components/promoCode/NewPromoForm";
import { Link } from "react-router-dom";
import { ViewExactDate } from "../../utils/DateViewer";
import ReactPaginate from "react-paginate";
import { retrieveAgentReports } from "../../store/actions/reportsActions";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ViewReportUsers from "../../components/affiliate/ViewReportUsers"; // Import css

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		textAlign: "center",
		maxWidth: "45%",
	},
};
class AffiliateDashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: {},
			modalIsOpen: false,
		};
	}

	openModal() {
		this.setState({
			modalIsOpen: true,
		});
	}

	closeModal() {
		this.setState({
			modalIsOpen: false,
		});
	}

	setPageData() {
		this.props.setPageData({
			title: "Dashboard",
			pageID: "home_page",
			totalUsers: "...",
		});
	}

	componentDidMount() {
		this.setPageData();
		this.loadAffiliates();
	}

	componentWilMount() {}

	loadAffiliates = async (event) => {
		this.props.retrievePromoCodes();
		this.props.retrieveAgentReports();
	};

	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};
	submitHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		let { firstName, lastName, email, userID } = this.state;

		this.props.insertPromoCodes(
			{ userID },
			this.props.history,
			this.stopLoading,
			this.resetFormData
		);
	};
	deletePromoHandler = (ID) => {
		confirmAlert({
			title: "Remove promo",
			message: "Are you sure to remove this promo code?",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						this.props.deletePromoCodes({ ID });
					},
				},
				{
					label: "No",
				},
			],
		});
	};

	render() {
		this.props.bodyClassController(this.props.pageData);
		let promoCodes;
		let { pageData } = this.props;
		let { promoData } = this.props;
		let { viewUsers } = this.state;

		let { auth } = this.props;
		let { user } = auth;

		if (
			promoData &&
			promoData.data &&
			promoData.pagination.data_total > 0
		) {
			promoCodes = promoData.data;
		}

		let { reportData } = this.props;
		let allReports, userPagination, showPagination;
		if (reportData && reportData.data) {
			allReports = reportData.data.length ? reportData.data : null;
			userPagination = reportData.pagination
				? reportData.pagination
				: null;

			showPagination = userPagination.page_last > 1 ? true : false;
		}

		return (
			<>
				<Helmet>
					{" "}
					<title>{pageData.title}</title>{" "}
				</Helmet>
				<PrimaryMenu />

				<div className="container-fluid">
					<div className="row page-body">
						<AffiliateSideMenu />

						<div className="col-md-10">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{pageData.title}</h1>
									</div>
								</div>

								<div className="page-content p-4">
									<div>
										<b>Dynamic Links</b>
										<p>
											Dynamic links are designed to give
											you credit for a sale of the
											NextCard App. To get credit for the
											sale your prospect or client must
											click on this link prior to going to
											the app store. This dynamic link can
											be linked to a button, webpage,
											Banner ads, or any combination of
											these things. The more places your
											“dynamic link is, the better your
											odds of getting credit for the sale
											are.
										</p>

										<div className="dynamic-links">
											{`https://nextcard.me/get/${user.UserID}`}
										</div>
									</div>

									<hr />

									<div>
										<b>Promotional Codes</b>
										<button
											className="btn color-primary"
											onClick={() => this.openModal()}
										>
											<span className="icon-wrapper">
												<Icofont icon="icofont-plus-circle" />
											</span>
										</button>

										<Modal
											isOpen={this.state.modalIsOpen}
											onRequestClose={
												this.state.closeModal
											}
											// style={customStyles}
											contentLabel="Example Modal"
										>
											<div className="basic-modal">
												<div className="modal-header">
													<h3>Add Promo Code</h3>
													<button
														className="btn close-modal"
														type={`button`}
														onClick={() => {
															this.setState({
																modalIsOpen:
																	null,
															});
														}}
													>
														<i className="icofont-close-line"></i>
													</button>
												</div>
												<div className="modal-content">
													<p>
														Promo Codes are also
														used to track your
														sales. If a customer
														uses a promo code that
														is linked to you, you
														will also receive credit
														for that sale. The codes
														can be entered before
														the sale om sign up, or
														the user can enter it
														after they are
														subscribers by going to
														the main menu in their
														account.
													</p>

													<NewPromoForm />
												</div>
											</div>
										</Modal>

										<p>
											Promo Codes are also used to track
											your sales. If a customer uses a
											promo code that is linked to you,
											you will also receive credit for
											that sale. The codes can be entered
											before the sale om sign up, or the
											user can enter it after they are
											subscribers by going to the main
											menu in their account.
										</p>

										<div className="promo-codes">
											{promoCodes
												? promoCodes.map((promo) => {
														return (
															<div className="single-code">
																<Icofont
																	onClick={() => {
																		this.deletePromoHandler(
																			promo.ID
																		);
																	}}
																	icon="icofont-trash"
																	className={`text-danger mr-1`}
																/>
																{
																	promo.promoCode
																}
															</div>
														);
												  })
												: ""}
										</div>
									</div>

									<hr />
									<div>
										<b>Commissions / Statements</b>
										<p>
											Statements are here to show you your
											commissions based on the period.
											when we issue a check to you it will
											show the period in which you were
											paid for. your commissions will be
											paid via PayPal, if you have not
											provided your PayPal act info, do by
											clicking <a href="#">here</a>.
										</p>

										<div className="data-viewer data-affiliates-affiliate">
											<div className="viewer-header">
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Title
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Statement date
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Commission
														</div>
													</div>
												</div>
												<div className="header-item">
													<div className="item-left">
														<div className="item-name">
															Payment
														</div>
													</div>
												</div>
											</div>
											<div className="viewer-content">
												{allReports
													? allReports.map((item) => {
															return (
																<div className="viewer-data">
																	<div className="data-single">
																		<div
																			className="single-content"
																			data-label={`Title`}
																		>
																			<span
																				className={`color-primary cursor-pointer`}
																				onClick={() => {
																					this.setState(
																						{
																							selectedReport:
																								item,
																							reportDetailsModalIsOpen: true,
																							viewUsers:
																								null,
																						}
																					);
																				}}
																			>
																				{
																					item.reportTitle
																				}
																			</span>
																		</div>
																	</div>
																	<div className="data-single">
																		<div
																			className="single-content"
																			data-label={`Period`}
																		>
																			<ViewExactDate
																				date={
																					item.rangeFrom
																				}
																				format="MM-DD-YYYY"
																			/>
																			{
																				" to "
																			}
																			<ViewExactDate
																				date={
																					item.rangeTo
																				}
																				format="MM-DD-YYYY"
																			/>
																		</div>
																	</div>
																	<div className="data-single">
																		<div
																			className="single-content"
																			data-label={`Commission`}
																		>
																			{parseFloat(
																				item.totalCommission
																			) >
																			0
																				? "$" +
																				  parseFloat(
																						item.totalCommission
																				  ).toFixed(
																						2
																				  )
																				: "0"}
																		</div>
																	</div>
																	<div className="data-single">
																		<div
																			className="single-content"
																			data-label={`Payment status`}
																		>
																			{(() => {
																				if (
																					item.paymentStatus ===
																					"1"
																				) {
																					return (
																						<>
																							{
																								"Paid at "
																							}
																							<ViewExactDate
																								date={
																									item.paymentDate
																								}
																								format="MM-DD-YYYY"
																							/>
																						</>
																					);
																				} else {
																					return "Pending";
																				}
																			})()}
																		</div>
																	</div>
																</div>
															);
													  })
													: ""}
											</div>

											{showPagination ? (
												<div className="section-pagination">
													<font>
														Showing{" "}
														{
															userPagination.data_start
														}{" "}
														to{" "}
														{
															userPagination.data_end
														}{" "}
														out of{" "}
														{
															userPagination.data_total
														}
													</font>
													<nav
														className="pagination-nav"
														aria-label="..."
													>
														<ReactPaginate
															previousLabel={
																"previous"
															}
															nextLabel={"next"}
															breakLabel={"..."}
															breakClassName={
																"break-me"
															}
															pageCount={
																userPagination.page_last
															}
															initialPage={
																userPagination.page -
																1
															}
															marginPagesDisplayed={
																2
															}
															pageRangeDisplayed={
																5
															}
															containerClassName={
																"pagination"
															}
															previousClassName={
																"page-item"
															}
															nextClassName={
																"page-item"
															}
															pageClassName={
																"page-item"
															}
															subContainerClassName={
																"pages pagination"
															}
															activeClassName={
																"active"
															}
															pageLinkClassName={
																"page-link"
															}
															previousLinkClassName={
																"page-link"
															}
															nextLinkClassName={
																"page-link"
															}
															onPageChange={
																this
																	.handlePagination
															}
														/>
													</nav>
												</div>
											) : (
												""
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/*Show all modals*/}
				{ViewReportModal(this)}
			</>
		);

		function ViewReportModal(that) {
			let { isLoading, selectedReport: item } = that.state;

			let totalCommission = 0;
			let premiumCommission;
			if (item) {
				totalCommission = parseFloat(item.totalCommission);
				premiumCommission =
					item.premiumUsers > 0 ? "$" + item.premiumUsers * 5 : 0;
			}
			return (
				<Modal
					isOpen={that.state.reportDetailsModalIsOpen}
					contentLabel="Example Modal a"
				>
					{item ? (
						<div className="basic-modal">
							<div className="modal-header">
								<h3>Report details</h3>
								<button
									className="btn close-modal"
									type={`button`}
									onClick={() => {
										that.setState({
											reportDetailsModalIsOpen: null,
										});
									}}
								>
									<i className="icofont-close-line" />
								</button>
							</div>
							<div className="modal-content">
								<div className="agent-affiliate-detailed-report">
									<div className="report-section">
										<div className="section-head">
											<div className="double-title">
												<div className="left">
													{item.reportTitle}
												</div>
												<div className="right">
													{`Statement Date `}
													<ViewExactDate
														date={item.rangeFrom}
														format="MM-DD-YYYY"
													/>
													{` - `}
													<ViewExactDate
														date={item.rangeTo}
														format="MM-DD-YYYY"
													/>
												</div>
											</div>
										</div>
										<div className="single-details">
											<div className="commission-summery">
												<table className="table table-bordered">
													<tr>
														<th>users</th>
														<th>type</th>
														<th>per user</th>
														<th>total</th>
													</tr>
													{/*Free users*/}
													<tr>
														<td
															className={`cursor-pointer`}
															onClick={() => {
																that.setState({
																	viewUsers: {
																		type: "free",
																		snapshotID:
																			item.snapshotID,
																	},
																});
															}}
														>
															{item.freeUsers}
														</td>
														<td>Free</td>
														<td>0</td>
														<td>0</td>
													</tr>
													{/*Premium users*/}
													<tr>
														<td
															className={`cursor-pointer`}
															onClick={() => {
																that.setState({
																	viewUsers: {
																		type: "trial",
																		snapshotID:
																			item.snapshotID,
																	},
																});
															}}
														>
															{item.trialUsers}
														</td>
														<td>Trial</td>
														<td>0</td>
														<td>0</td>
													</tr>
													{/*noPlanTaken users*/}
													<tr>
														<td
															className={`cursor-pointer`}
															onClick={() => {
																that.setState({
																	viewUsers: {
																		type: "noPlanTaken",
																		snapshotID:
																			item.snapshotID,
																	},
																});
															}}
														>
															{
																item.noPlanTakenUsers
															}
														</td>
														<td>Inactive</td>
														<td>0</td>
														<td>0</td>
													</tr>
													{/*Enterprise users*/}
													<tr>
														<td
															className={`cursor-pointer`}
															onClick={() => {
																that.setState({
																	viewUsers: {
																		type: "premium",
																		snapshotID:
																			item.snapshotID,
																	},
																});
															}}
														>
															{item.premiumUsers}
														</td>
														<td>Premium</td>
														<td>$5</td>
														<td>
															{premiumCommission}
														</td>
													</tr>
													{/*enterprise users*/}
													<tr>
														<td
															className={`cursor-pointer`}
															onClick={() => {
																that.setState({
																	viewUsers: {
																		type: "enterprise",
																		snapshotID:
																			item.snapshotID,
																	},
																});
															}}
														>
															{
																item.enterpriseUsers
															}
														</td>
														<td>Enterprise</td>
														<td>0</td>
														<td>0</td>
													</tr>
													{/*business users*/}
													<tr>
														<td
															className={`cursor-pointer`}
															onClick={() => {
																that.setState({
																	viewUsers: {
																		type: "business",
																		snapshotID:
																			item.snapshotID,
																	},
																});
															}}
														>
															{item.businessUsers}
														</td>
														<td>Business</td>
														<td>0</td>
														<td>0</td>
													</tr>
												</table>
											</div>
											<div className="commission-action">
												<div className="action-single">
													{item.paymentStatus ===
													"1" ? (
														<>
															<i className="big icofont-check-circled text-success"></i>
															<font>PAID ON</font>
															<ViewExactDate
																date={
																	item.paymentDate
																}
																format="MM-DD-YYYY"
															/>
														</>
													) : (
														<>
															<i className="big icofont-check-circled"></i>
															<p>Pending</p>
														</>
													)}
												</div>
												<div className="action-summery">
													{`$${totalCommission} Total
																	Commissions`}
												</div>
											</div>
										</div>

										{viewUsers ? (
											<div className="show-users">
												<ViewReportUsers
													viewUsers={viewUsers}
												/>
											</div>
										) : (
											""
										)}
									</div>
								</div>
							</div>
						</div>
					) : (
						""
					)}
				</Modal>
			);
		}
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
	userData: state.userData,
	promoData: state.promoData,
	reportData: state.reportData,
});
const mapDispatchToProps = {
	logout,
	setPageData,
	bodyClassController,
	insertPromoCodes,
	retrievePromoCodes,
	retrieveAgentReports,
	deletePromoCodes,
};
export default connect(mapStateToProps, mapDispatchToProps)(AffiliateDashboard);

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
