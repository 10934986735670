import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../store/actions/authActions'
import ReactPaginate from 'react-paginate';
import Icofont from 'react-icofont';
import { ViewDate } from '../../utils/DateViewer'


import bodyClassController from '../../store/utils/bodyClassController'
import PrimaryMenu from '../../components/menu/headerPrimaryMenu'
import SideMenu from '../../components/menu/sideMenu'
import PageBreadcrumb from '../../components/default/pageBreadcrumb'

import setPageData from '../../store/actions/setPageData'
import { retrievePromoCodes } from '../../store/actions/promoCodesActions'

class PromoCodesPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ReOrderModalOpen: null,
			NewTicketModalOpen: false,
			user: '',
			password: '',
			error: {},
			page: 1,
		}
	}
	changeHandler = event => {
		this.setState({
			[event.target.name]: event.target.value
		})
	}

	submitHandler = event => {
		if (event) {
			event.preventDefault()
		}

		let { page } = this.state;

		this.props.retrievePromoCodes({ page })
	}

	setPageData() {
		this.props.setPageData({
			title: 'Promo codes',
			pageID: 'promo-code'
		});
	}

	componentDidMount() {
		this.setPageData()
		this.submitHandler()
	}

	componentWilMount() {
	}

	handlePagination = data => {
		this.setState({
			page: data.selected + 1,
		}, () => {
			this.submitHandler()
		});
	}

	render() {
		this.props.bodyClassController(this.props.pageData)

		let { promoData, pageData } = this.props
		let allPromos, promoPagination, showPagination;
		if (promoData && promoData.data) {
			allPromos = promoData.data.length ? promoData.data : null
			promoPagination = promoData.pagination ? promoData.pagination : null

			showPagination = promoPagination.page_last > 1 ? true : false
		}

		return (
			<>
				<Helmet> <title>{pageData.title}</title> </Helmet>
				<PrimaryMenu className="page-header" />

				<div className="container-fluid">

					<div className="row page-body">
						<SideMenu />

						<div className="col-md-10 page-body">
							<div className="container">
								<PageBreadcrumb />

								<div className="page-header">
									<div className="header-title">
										<h1>{pageData.title}</h1>
										<Link to='/promo-code/new' className="btn btn-primary ml-3">
											<span className="icon-wrapper">
												<Icofont icon="icofont-plus-circle" />
											</span>
											<span className="icon-text">Add New</span>
										</Link>
									</div>
								</div>

								<div className="page-content">
									<div className="list-panels table-responsive">
										<table className="table data-table table-hover table-light">
											<thead>
												<tr>
													<th scope="col">promo code</th>
													<th scope="col">created at</th>
													<th scope="col">author</th>
													<th scope="col">used</th>
													<th scope="col">status</th>
												</tr>
											</thead>

											<tbody>
												{
													allPromos ? allPromos.map(item => {

														let authDisplayName = item.authID ? `${item.authFirstName} ${item.authLastName}` : ''
														return (
															<tr
																key={item.ID}
																className={['active', 'pending'].indexOf(item.status) > -1 ? '' : 'disabled'}
															>
																<td data-label="Promo code">{item.promoCode}</td>
																<td data-label="Created"><ViewDate date={item.date} /></td>
																<td data-label="Author">{authDisplayName}</td>
																<td data-label="Used">{item.totalUsed}</td>
																<td data-label="Status">{item.status}</td>
															</tr>
														)
													})
														: ''
												}
											</tbody>
										</table>
										{
											showPagination ?
												<div className="section-pagination">
													<font>Showing {promoPagination.data_start} to {promoPagination.data_end} out of {promoPagination.data_total}</font>
													<nav
														className="pagination-nav"
														aria-label="...">
														<ReactPaginate
															previousLabel={'previous'}
															nextLabel={'next'}
															breakLabel={'...'}
															breakClassName={'break-me'}
															pageCount={promoPagination.page_last}
															initialPage={(promoPagination.page - 1)}
															marginPagesDisplayed={2}
															pageRangeDisplayed={5}
															containerClassName={'pagination'}
															previousClassName={'page-item'}
															nextClassName={'page-item'}
															pageClassName={'page-item'}
															subContainerClassName={'pages pagination'}
															activeClassName={'active'}
															pageLinkClassName={'page-link'}
															previousLinkClassName={'page-link'}
															nextLinkClassName={'page-link'}
															onPageChange={this.handlePagination}
														/>
													</nav>
												</div>

												: ''
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}


	openReOrderModal = orderInfo => {
		this.setState({
			ReOrderModalOpen: orderInfo
		})
	}
	closeReOrderModal = () => {
		this.setState({
			ReOrderModalOpen: null
		})
	}



	openNewTicketModal = (item) => {
		this.setState({
			NewTicketModalOpen: item.OrderID
		})
	}

	closeNewTicketModal = () => {
		this.setState({
			NewTicketModalOpen: false
		})
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	pageData: state.pageData,
	promoData: state.promoData,
})
const mapDispatchToProps = ({
	logout,
	setPageData,
	bodyClassController,
	retrievePromoCodes,
})
export default connect(mapStateToProps, mapDispatchToProps)(PromoCodesPage)