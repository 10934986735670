import React from "react";
import { connect } from "react-redux";
import {
	registerAffiliates,
	retrieveAgentSummeryTemp,
	retrieveUsersTemp,
	updateAffiliateAction,
	updateUserAction,
} from "../../store/actions/userActions";
import AsyncSelect from "react-select/async";
import moment from "moment";

class AffiliatePreview extends React.Component {
	state = {
		error: {},
		isLoading: false,
	};

	static getDerivedStateFromProps(nextProps, prevProps) {
		return null;
	}
	stopLoading = () => {
		this.setState({ isLoading: false });
	};
	changeHandler = async (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	resetFormData = () => {
		this.setState({
			firstName: "",
			lastName: "",
			email: "",
		});
	};

	updateProfileHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: "profileUpdate" });

		let {
			profileFirstName,
			profileLastName,
			profileAddress,
			profileAddressB,
			profileCity,
			profileState,
			profilePostCode,
			profileEmail,
		} = this.state;

		let { userID } = this.props;

		let updateData = {
			UserID: userID,
			_uItem: {
				U_Name: `${profileFirstName} ${profileLastName}`,
				U_FirstName: profileFirstName,
				U_LastName: profileLastName,
				U_AddressA: profileAddress,
				U_AddressB: profileAddressB,
				U_City: profileCity,
				U_State: profileState,
				U_PostCode: profilePostCode,
				U_Email: profileEmail,
			},
		};
		await this.props.updateUserAction(
			updateData,
			this.props.history,
			this.stopLoading,
			this.resetFormData
		);
	};

	updateAffiliateHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: "affiliateUpdate" });

		let { affiliatePayPal, affiliateSSN, affiliateContactSignDate } =
			this.state;

		let { userID } = this.props;

		let updateData = {
			UserID: userID,
			_uItem: {
				PayPal: affiliatePayPal,
				SSN: affiliateSSN,
				contactSignDate: affiliateContactSignDate,
			},
		};
		await this.props.updateAffiliateAction(
			updateData,
			this.props.history,
			this.stopLoading,
			this.resetFormData
		);
		console.log("update done");
	};

	removeAffiliateHandler = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: "affiliateStatusUpdate" });

		let { userID } = this.props;

		let updateData = {
			UserID: userID,
			_uItem: {
				affiliateStatus: `0`,
			},
		};
		await this.props.updateUserAction(
			updateData,
			this.props.history,
			this.stopLoading,
			this.resetFormData
		);
	};

	retrieveSummery = async (event) => {
		if (event) {
			event.preventDefault();
		}

		this.setState({ isLoading: true });

		let { userID } = this.props;

		let agentSummery = await this.props.retrieveAgentSummeryTemp({
			UserID: userID,
		});
		// Set input values
		console.log(agentSummery);

		// Start of format date for input date field
		let contactSignDate;
		if (agentSummery?.affiliateDetails?.contactSignDate) {
			let guaranteedAccessTill =
				agentSummery.affiliateDetails.contactSignDate;
			let format = "YYYY-MM-DD";
			let datum = Date.parse(guaranteedAccessTill);

			contactSignDate = moment(datum).format(format);
		}
		// Start of format date for input date field

		this.setState({
			isLoading: false,
			agentSummery,
			profileFirstName: agentSummery.profileInfo.firstName,
			profileLastName: agentSummery.profileInfo.lastName,
			profileAddress: agentSummery.profileInfo.address,
			profileAddressB: agentSummery.profileInfo.addressB,
			profileCity: agentSummery.profileInfo.city,
			profileState: agentSummery.profileInfo.state,
			profilePostCode: agentSummery.profileInfo.postCode,
			profileEmail: agentSummery.profileInfo.email,
			affiliatePayPal: agentSummery.affiliateDetails?.PayPal,
			affiliateSSN: agentSummery.affiliateDetails?.SSN,
			affiliateContactSignDate: contactSignDate,
		});
	};

	componentDidMount() {
		this.retrieveSummery();
	}

	render() {
		let {
			isLoading,
			selectedUser,
			agentSummery,
			editProfileMode,
			editAffiliateMode,
			profileFirstName,
			profileLastName,
			profileAddress,
			profileAddressB,
			profileCity,
			profileState,
			profilePostCode,
			profileEmail,
			affiliatePayPal,
			affiliateSSN,
			affiliateContactSignDate,
		} = this.state;

		let profileInfo, affiliateDetails, promoCodes;
		if (agentSummery) {
			profileInfo = agentSummery.profileInfo;
			affiliateDetails = agentSummery.affiliateDetails;
			promoCodes = agentSummery.promoCodes;
		}

		return (
			<>
				{(() => {
					if (agentSummery == null) {
						return <h1 className="text-center">Loading...</h1>;
					} else {
						return (
							<div className="row">
								<div className="col-md-12 p-4">
									<div className="single-section">
										<div className="row">
											<div className="col-md-6 mb-4">
												<h5>
													Affiliate contact info
													{"  "}
													{!editProfileMode ? (
														<i
															onClick={() => {
																this.setState({
																	editProfileMode: true,
																});
															}}
															className="icofont-edit text-primary a"
														/>
													) : (
														<button
															onClick={() => {
																this.setState({
																	editProfileMode: false,
																});
															}}
															className="btn"
															type={`button`}
														>
															Cancel edit
														</button>
													)}
												</h5>
												<form
													onSubmit={
														this
															.updateProfileHandler
													}
												>
													<table className={`table`}>
														<tr>
															<td>First name:</td>
															{editProfileMode ? (
																<td>
																	<input
																		type="text"
																		name="profileFirstName"
																		value={
																			profileFirstName
																		}
																		onChange={
																			this
																				.changeHandler
																		}
																		id="profileFirstName"
																		className={`form-control`}
																	/>
																</td>
															) : (
																<td>
																	{profileInfo
																		? profileInfo.firstName
																		: ".."}
																</td>
															)}
														</tr>
														<tr>
															<td>Last name:</td>
															{editProfileMode ? (
																<td>
																	<input
																		type="text"
																		name="profileLastName"
																		value={
																			profileLastName
																		}
																		onChange={
																			this
																				.changeHandler
																		}
																		id="profileLastName"
																		className={`form-control`}
																	/>
																</td>
															) : (
																<td>
																	{profileInfo
																		? profileInfo.lastName
																		: ".."}
																</td>
															)}
														</tr>
														<tr>
															<td>Address:</td>
															{editProfileMode ? (
																<td>
																	<input
																		type="text"
																		name="profileAddress"
																		value={
																			profileAddress
																		}
																		onChange={
																			this
																				.changeHandler
																		}
																		id="profileAddress"
																		className={`form-control`}
																	/>
																</td>
															) : (
																<td>
																	{profileInfo
																		? `${profileInfo.address}`
																		: ".."}
																</td>
															)}
														</tr>
														<tr>
															<td>Address 2:</td>
															{editProfileMode ? (
																<td>
																	<input
																		type="text"
																		name="profileAddressB"
																		value={
																			profileAddressB
																		}
																		onChange={
																			this
																				.changeHandler
																		}
																		id="profileAddressB"
																		className={`form-control`}
																	/>
																</td>
															) : (
																<td>
																	{profileInfo
																		? `${profileInfo.addressB}`
																		: ".."}
																</td>
															)}
														</tr>
														<tr>
															<td>City:</td>
															{editProfileMode ? (
																<td>
																	<input
																		type="text"
																		name="profileCity"
																		value={
																			profileCity
																		}
																		onChange={
																			this
																				.changeHandler
																		}
																		id="profileCity"
																		className={`form-control`}
																	/>
																</td>
															) : (
																<td>
																	{profileInfo
																		? `${profileInfo.city}`
																		: ".."}
																</td>
															)}
														</tr>
														<tr>
															<td>State:</td>
															{editProfileMode ? (
																<td>
																	<input
																		type="text"
																		name="profileState"
																		value={
																			profileState
																		}
																		onChange={
																			this
																				.changeHandler
																		}
																		id="profileState"
																		className={`form-control`}
																	/>
																</td>
															) : (
																<td>
																	{profileInfo
																		? `${profileInfo.state}`
																		: ".."}
																</td>
															)}
														</tr>
														<tr>
															<td>Zip:</td>
															{editProfileMode ? (
																<td>
																	<input
																		type="text"
																		name="profilePostCode"
																		value={
																			profilePostCode
																		}
																		onChange={
																			this
																				.changeHandler
																		}
																		id="profilePostCode"
																		className={`form-control`}
																	/>
																</td>
															) : (
																<td>
																	{profileInfo
																		? `${profileInfo.postCode}`
																		: ".."}
																</td>
															)}
														</tr>
														<tr>
															<td>Email:</td>
															{editProfileMode ? (
																<td>
																	<input
																		type="email"
																		name="profileEmail"
																		value={
																			profileEmail
																		}
																		onChange={
																			this
																				.changeHandler
																		}
																		id="profileEmail"
																		className={`form-control`}
																	/>
																</td>
															) : (
																<td>
																	{profileInfo
																		? `${profileInfo.email}`
																		: ".."}
																</td>
															)}
														</tr>
														{editProfileMode ? (
															<tr>
																<td
																	className={`text-left`}
																>
																	<button
																		onClick={() => {
																			this.setState(
																				{
																					editProfileMode: false,
																				}
																			);
																		}}
																		className="btn"
																		type={`button`}
																	>
																		Cancel
																	</button>
																</td>
																<td
																	className={`text-right`}
																>
																	<button
																		className="btn btn-primary btn-sm"
																		type={
																			isLoading ===
																			"profileUpdate"
																				? `button`
																				: `submit`
																		}
																	>
																		{" "}
																		{isLoading ===
																		"profileUpdate" ? (
																			<i className="fas fa-spinner fa-spin"></i>
																		) : (
																			"Update"
																		)}{" "}
																	</button>
																</td>
															</tr>
														) : (
															""
														)}
													</table>
												</form>
											</div>

											<div className="col-md-6 mb-4">
												<div className="row">
													<div className="col-md-12 mb-4">
														<h5>
															Affiliate account
															details
														</h5>
														<table>
															<tr>
																<td>
																	User ID:
																</td>
																<td>
																	{profileInfo
																		? `${profileInfo.UserID}`
																		: ".."}
																</td>
															</tr>
															<tr>
																<td>
																	Dynamic
																	link:
																</td>
																<td>
																	{profileInfo
																		? `https://nextcard.me/get/${profileInfo.UserID}`
																		: ".."}
																</td>
															</tr>
															<tr>
																<td>
																	Promo codes:
																</td>
																<td>
																	{promoCodes
																		? promoCodes.map(
																				(
																					i
																				) => {
																					return (
																						i.promoCode +
																						" , "
																					);
																				}
																		  )
																		: ".."}
																</td>
															</tr>
														</table>
													</div>
													<div className="col-md-12 mb-4">
														<h5>
															This is data entered
															manually
															{"  "}
															{!editAffiliateMode ? (
																<i
																	onClick={() => {
																		this.setState(
																			{
																				editAffiliateMode: true,
																			}
																		);
																	}}
																	className="icofont-edit text-primary a"
																/>
															) : (
																<button
																	onClick={() => {
																		this.setState(
																			{
																				editAffiliateMode: false,
																			}
																		);
																	}}
																	className="btn"
																	type={`button`}
																>
																	Cancel edit
																</button>
															)}
														</h5>

														<form
															onSubmit={
																this
																	.updateAffiliateHandler
															}
														>
															<table>
																<tr>
																	<td>
																		PayPal
																		email:
																	</td>
																	{editAffiliateMode ? (
																		<td>
																			<input
																				type="email"
																				name="affiliatePayPal"
																				value={
																					affiliatePayPal
																				}
																				onChange={
																					this
																						.changeHandler
																				}
																				id="affiliatePayPal"
																				className={`form-control`}
																			/>
																		</td>
																	) : (
																		<td>
																			{affiliateDetails
																				? affiliateDetails.PayPal
																				: ".."}
																		</td>
																	)}
																</tr>
																<tr>
																	<td>
																		SSN/Tax
																		ID:
																	</td>
																	{editAffiliateMode ? (
																		<td>
																			<input
																				type="text"
																				name="affiliateSSN"
																				value={
																					affiliateSSN
																				}
																				onChange={
																					this
																						.changeHandler
																				}
																				id="affiliateSSN"
																				className={`form-control`}
																			/>
																		</td>
																	) : (
																		<td>
																			{affiliateDetails
																				? affiliateDetails.SSN
																				: ".."}
																		</td>
																	)}
																</tr>
																<tr>
																	<td>
																		Contact
																		sign
																		date:
																	</td>
																	{editAffiliateMode ? (
																		<td>
																			<input
																				type="date"
																				name="affiliateContactSignDate"
																				value={
																					affiliateContactSignDate
																				}
																				onChange={
																					this
																						.changeHandler
																				}
																				id="affiliateContactSignDate"
																				className={`form-control`}
																			/>
																		</td>
																	) : (
																		<td>
																			{affiliateDetails
																				? affiliateDetails.contactSignDate
																				: ".."}
																		</td>
																	)}
																</tr>

																{editAffiliateMode ? (
																	<tr>
																		<td
																			className={`text-left`}
																		>
																			<button
																				onClick={() => {
																					this.setState(
																						{
																							editAffiliateMode: false,
																						}
																					);
																				}}
																				className="btn"
																				type={`button`}
																			>
																				Cancel
																			</button>
																		</td>
																		<td
																			className={`text-right`}
																		>
																			<button
																				className="btn btn-primary btn-sm"
																				type={
																					isLoading ===
																					"affiliateUpdate"
																						? `button`
																						: `submit`
																				}
																			>
																				{" "}
																				{isLoading ===
																				"affiliateUpdate" ? (
																					<i className="fas fa-spinner fa-spin"></i>
																				) : (
																					"Update"
																				)}{" "}
																			</button>
																		</td>
																	</tr>
																) : (
																	""
																)}
															</table>
														</form>
													</div>
												</div>
											</div>

											<div className="col-md-12 mb-4">
												<div className="form-actions text-right">
													<form
														onSubmit={
															this
																.removeAffiliateHandler
														}
													>
														<button
															type={
																isLoading ===
																"affiliateStatusUpdate"
																	? "button"
																	: "submit"
															}
															className="btn btn-primary is-invalid"
														>
															{" "}
															{isLoading ===
															"affiliateStatusUpdate" ? (
																<i className="fas fa-spinner fa-spin"></i>
															) : (
																"Convert Back To User Only "
															)}{" "}
														</button>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					}
				})()}
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	pageData: state.pageData,
});
const mapDispatchToProps = {
	registerAffiliates,
	retrieveUsersTemp,
	retrieveAgentSummeryTemp,
	updateUserAction,
	updateAffiliateAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(AffiliatePreview);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
